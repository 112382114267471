import type { AxiosError } from 'axios';
import axios from 'axios';
import { ChatFooter } from 'Components/Chat/ChatFooter';
import ContextContentContainer from 'Containers/ContextContent';
import ContextHeaderContainer from 'Containers/ContextHeader';
import { MobXProviderContext, observer } from 'mobx-react';
import { fetchOptOutStatus } from 'Modules/conversation/index.requests';
import { useConversationStore } from 'Modules/conversation/index.store';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { isNullOrUndefined } from 'util';

export const ChatLayout: React.FC = observer(() => {
  const { conversation, person, notification } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const [renderChat, setRenderChat] = React.useState(false);
  const navigate = useNavigate();

  const { conversationId } = useParams();

  React.useLayoutEffect(() => {
    setRenderChat(false);
  }, [conversation.CurrentConversation?.id, person.loggedInPersonId]);

  React.useEffect(() => {
    void (async () => {
      const response = await conversation
        .loadConversationByIdIfMissingGet(conversationId)
        .catch((err: AxiosError) => {
          const resStatus = err.response.status;
          if (resStatus === 403 || resStatus === 404) {
            navigate('/chat');
          } else throw new Error(err.message);
        });
      if (response && response.data) {
        const currentConversation = conversation?.CurrentConversation;
        if (
          ['Channel', 'Group', 'OneOnOne'].includes(
            currentConversation?.grouping
          )
        ) {
          const otherParticipants = currentConversation.participants.filter(
            ({ personId, phone }) =>
              personId !== person.loggedInPersonId && phone
          );

          if (otherParticipants.length > 0) {
            try {
              // Select the participants who don't already have opt-out status
              const otherParticipantsPhoneNumbers = otherParticipants
                .filter(({ phone }) =>
                  isNullOrUndefined(
                    useConversationStore
                      .getState()
                      .getOptOutPhoneNumberInfo(phone)
                  )
                )
                .map(({ phone }) => phone);

              await fetchOptOutStatus(otherParticipantsPhoneNumbers);
            } catch (error) {
              if (axios.isAxiosError(error) && error.response && error.response.status === 404) {
                // 404 is expected when 10DLC is not enabled
                // We don't want to show a notification for this
              } else {
                notification.addAxiosErrorNotification(
                  error,
                  'Error fetching opt out status'
                );
              }
            }

            setRenderChat(true);
            return;
          }
        }

        setRenderChat(true);
      }
    })();

    return () => {
      setRenderChat(false);
    };
  }, [conversation, conversation?.CurrentConversation, conversationId, navigate, notification, person.loggedInPersonId]);

  return renderChat ? (
    <>
      <ContextHeaderContainer />
      <ContextContentContainer />
      <ChatFooter />
    </>
  ) : null;
});
