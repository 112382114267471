import { MobXProviderContext } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { useBannerStore } from '../../../modules/ui/banner/index.store';

const useShowConnectionBanner = () => {
  const {
    pusher: { isOnline },
    phoneCall: { isWebRTCConnected, isWebRTCReconnecting },
    person: { IsLoggedIn },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const [hasInternetConnection, setHasInternetConnection] =
    React.useState(true);

  const {
    showConnectionBanner,
    setShowConnectionBanner,
    toleranceTimeSurpassed,
    setToleranceTimeSurpassed,
  } = useBannerStore();

  const connectionWasOff = useRef<boolean>(false);
  const hideBannerTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();

  useEffect(() => {
    const handleOnline = () => setHasInternetConnection(true);
    const handleOffline = () => setHasInternetConnection(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    !toleranceTimeSurpassed &&
      setTimeout(() => {
        setToleranceTimeSurpassed(true);
      }, 10000);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!toleranceTimeSurpassed || !IsLoggedIn) {
      setShowConnectionBanner(false);
      return;
    }

    if (
      !isOnline ||
      !hasInternetConnection ||
      !isWebRTCConnected ||
      isWebRTCReconnecting
    ) {
      if (hideBannerTimeout.current) {
        clearTimeout(hideBannerTimeout.current);
        hideBannerTimeout.current = undefined;
      }

      setShowConnectionBanner('offline');

      connectionWasOff.current = true;
      return;
    }

    if (
      connectionWasOff.current &&
      isOnline &&
      hasInternetConnection &&
      isWebRTCConnected &&
      !isWebRTCReconnecting
    ) {
      setShowConnectionBanner('online');

      connectionWasOff.current = false;

      hideBannerTimeout.current = setTimeout(() => {
        setShowConnectionBanner(false);
      }, 3000);
    }
  }, [
    isOnline,
    hasInternetConnection,
    isWebRTCConnected,
    isWebRTCReconnecting,
    toleranceTimeSurpassed,
    setShowConnectionBanner,
    IsLoggedIn,
  ]);

  useEffect(() => {
    return () => {
      if (hideBannerTimeout.current) {
        clearTimeout(hideBannerTimeout.current);
        hideBannerTimeout.current = undefined;
      }
    };
  }, []);

  return showConnectionBanner;
};

export default useShowConnectionBanner;
