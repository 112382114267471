import { CallLogCategoryType } from 'Components/CallLogs/types';

export const typesOfCallsId: CallLogCategoryType[] = [
  'missed',
  'outgoing',
  'incoming',
  'all',
];

export interface CallLogsQueryParams {
  typeOfCallId?: CallLogCategoryType;
  query?: string;
  enabled?: boolean;
  refetchInterval?: number | (() => number);
}

export interface IFetchCallLogs {
  pageParam?: string;
  callId: CallLogCategoryType;
  query: string;
}

export interface CallLogsMissedCountQueryParams {
  since: number;
}
export interface IFetchCallLogsMissedCount {
  since: number;
}
