import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';

const useShowDndBanner = () => {
  const { ui, person } = React.useContext<RootStoreProps>(MobXProviderContext);

  const showDndBanner =
    ui.shouldShowDndBanner &&
    ui.selectPersonPresenceStatus(person.loggedInPersonId).state ===
      'DoNotDisturb';

  return showDndBanner;
};

export default useShowDndBanner;
