import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import { MobXProviderContext } from 'mobx-react';
import { IPresenceResponse } from 'Models/PresenceModel';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import API from '~/api';
import { usePersonStore } from './index.store';
import { IAvailabilityPhoneNumberResponse, InboundNumber } from './index.types';

export const QUERY_KEY_CHECK_AVAILABILITY_PHONENUMBER =
  'person.fetchAvailabilityPhoneNumber';
export const QUERY_KEY_FETCH_PRESENCE = 'person.fetchPresence';

/*FIXME: For now we need to export this, so we can call on the mobx store (class instance)
 * We will also be able to rely on "useQueries" to parallelize the requests, instead of the Promise.allSettled
 */
export const fetchAvailabilityPhoneNumberQuery = async (
  inboundNumbers: InboundNumber[]
) => {
  const promises = inboundNumbers.map((inboundNumber) => {
    return API.get<IAvailabilityPhoneNumberResponse>(
      API_ENDPOINTS.CheckAvailabilityPhoneNumber(inboundNumber.number)
    );
  });

  const results = await Promise.allSettled(promises);

  const hasAnyAvailablePhoneNumber = results.some(
    (result) => result.status === 'fulfilled' && result.value.data.isAvailable
  );

  const campaignEnabled10Dlc = results.some(
    (result) =>
      result.status === 'fulfilled' &&
      result.value.data.campaignEnabled !== false
  );

  // FIXME: In the future when we dont need to call this via mobx store, we can use the setAvailabilityPhoneNumberSelector
  // with the usePersonStore hook
  usePersonStore.setState({
    isAvailablePhoneNumber: hasAnyAvailablePhoneNumber,
    campaignEnabled10Dlc,
  });

  return results.map((r) => r.status === 'fulfilled' && r.value.data);
};

export const fetchPresences = async (): Promise<IPresenceResponse> => {
  const presencesResponse = await API.get<IPresenceResponse>(
    API_ENDPOINTS.PresenceEveryone
  );

  return presencesResponse.data;
};

export const useFetchAvailabilityPhoneNumber = (
  inboundNumbers: InboundNumber[]
) =>
  useQuery({
    queryKey: [QUERY_KEY_CHECK_AVAILABILITY_PHONENUMBER, ...inboundNumbers],
    queryFn: async () => fetchAvailabilityPhoneNumberQuery(inboundNumbers),
  });

export const useFetchPresences = (
  enabled: boolean,
  refetchInterval: number | (() => number)
) => {
  const {
    ui: { loadAllPeoplePresenceGetSuccess },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  return useQuery({
    queryKey: [QUERY_KEY_FETCH_PRESENCE, enabled, refetchInterval],
    queryFn: async () => {
      const presences = await fetchPresences();
      loadAllPeoplePresenceGetSuccess(presences);
      return presences;
    },
    enabled,
    refetchInterval,
    gcTime: 0,
    staleTime: 0,
  });
};

export const usePerson = () => ({
  useFetchAvailabilityPhoneNumber,
  useFetchPresences,
});
