import editIconWhite from 'Assets/images/icon-edit-white.svg';
import loader from 'Assets/images/loader.gif';
import user from 'Assets/images/user-details.svg';
import { useNotificationStore } from 'Modules/notification/store';
import React, { useCallback } from 'react';
import { isFileLargerThan, isFileOfType } from 'Utils/fileUtils';
import { formatUserInitials } from 'Utils/formatUserInitials';
import { Styled } from './index.styles';
import { AvatarEditorProps } from './index.types';

const ALLOWED_MIME_TYPES = ['image/png', 'image/jpeg'];
const FORBIDDEN_EXTENSIONS = ['jfif'];

export const AvatarEditor: React.FC<AvatarEditorProps> = ({
  pictureUrl,
  isLoading,
  size = 'normal',
  name,
  isEditable = true,
  fileImportEvent,
  testid,
}) => {
  const { addNotify } = useNotificationStore();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const files = event.target?.files || [];

      if (!files || !files[0]) {
        return;
      }

      const file = files[0] as File;

      if (isFileLargerThan(file, 5)) {
        addNotify({
          type: 'error',
          dismissible: true,
          title: 'Error uploading image',
          message:
            'The image exceeds the supported size. Please select an image smaller than 5MB.',
        });

        // Clean the file input
        event.target.value = '';
        return;
      }

      if (!isFileOfType(file, ALLOWED_MIME_TYPES, FORBIDDEN_EXTENSIONS)) {
        addNotify({
          type: 'error',
          dismissible: true,
          title: 'Error uploading image',
          message:
            "We don't support the selected format. Please select an image in one of the following formats: PNG or JPEG.",
        });

        // Clean the file input
        event.target.value = '';
        return;
      }

      void fileImportEvent(event);
    },
    [addNotify, fileImportEvent]
  );

  const showInitials = name && !pictureUrl;

  return (
    <Styled.Container $size={size}>
      <Styled.ImageContainer
        $isIcon={!pictureUrl && !isLoading}
        $isInitials={!!name}
      >
        {isLoading ? (
          <Styled.Image src={loader} alt="" />
        ) : showInitials ? (
          <Styled.Initials>{formatUserInitials(name)}</Styled.Initials>
        ) : (
          <Styled.Image
            $isIcon={!pictureUrl}
            data-private
            src={pictureUrl || user}
            alt=""
          />
        )}
      </Styled.ImageContainer>
      {!isLoading && isEditable && (
        <>
          <Styled.Input
            data-private
            type="file"
            accept={ALLOWED_MIME_TYPES.join(', ')}
            onChange={handleChange}
            id="profilefileinput"
            data-testid={`${testid}-buttonEditProfileImage`}
          />
          <Styled.Label $size={size} htmlFor="profilefileinput">
            <Styled.PencilIcon $size={size} src={editIconWhite} />
          </Styled.Label>
        </>
      )}
    </Styled.Container>
  );
};
