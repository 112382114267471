import { ContentArea } from 'Components/shared/ContentArea';
import { SecondaryMenu } from 'Components/shared/SecondaryMenu';
import { SETTINGS_MENU_OPTIONS } from 'Constants/routing';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import { Outlet } from 'react-router';

import { Grid } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';

export const Settings: React.FC = observer(() => {
  const { ui } = React.useContext<RootStoreProps>(MobXProviderContext);

  return (
    <Grid.Column
      className="settings-container flex-row"
      onClick={() => ui.setDeletePopupDisplay(false)}
    >
      {!ui.hideSecondaryMenu && (
        <SecondaryMenu text="Settings" options={SETTINGS_MENU_OPTIONS} />
      )}
      <ContentArea>
        <Outlet />
      </ContentArea>
    </Grid.Column>
  );
});
