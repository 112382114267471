import { MESSAGE_CREATED_PUSHER_LOGS } from 'Constants/localstorage';
import localforage from 'localforage';

export const updatePusherLogs = async (log: Record<string, any>) => {
  const MAX_NUMBER_OF_LOGS = 10000;

  try {
    let logs = await localforage.getItem<Record<string, any>[]>(
      MESSAGE_CREATED_PUSHER_LOGS
    );

    if (!Array.isArray(logs)) {
      logs = [];
    }

    if (logs.length >= MAX_NUMBER_OF_LOGS) logs = logs.slice(1);

    logs.push(log);

    await localforage.setItem(MESSAGE_CREATED_PUSHER_LOGS, logs);
  } catch (error) {
    console.error('Error updating pusher logs:', error);
  }
};
