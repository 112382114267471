import { Banner } from 'Components/shared/Banner';
import { observer } from 'mobx-react';
import React from 'react';
import { ConnectionBannerWrapperProps } from './index.types';

export const ConnectionBannerWrapper = observer(
  ({ type }: ConnectionBannerWrapperProps) => {
    return (
      <>
        <Banner
          isFullWidth
          type={type === 'online' ? 'success' : 'warning'}
          id="connection-banner-wrapper"
          message={
            type === 'online'
              ? 'Connection reestablished.'
              : 'There are connection issues. Some features might be unavailable right now.'
          }
          showCloseButton={false}
          variant="inline"
        />
      </>
    );
  }
);
