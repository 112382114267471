import { DNDBannerWrapper } from 'Components/BannerWrapper/DNDBannerWrapper';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { ConnectionBannerWrapper } from './ConnectionBannerWrapper';
import useShowConnectionBanner from './hooks/useShowConnectionBanner';
import useShowDndBanner from './hooks/useShowDndBanner';
import { BannerWrapperProps } from './index.types';

export const BannerWrapper = observer(
  ({ defaultBanner }: BannerWrapperProps) => {
    const connectionBanner = useShowConnectionBanner();
    const showDndBanner = useShowDndBanner();

    const banner = useMemo(() => {
      if (connectionBanner) {
        return <ConnectionBannerWrapper type={connectionBanner} />;
      }

      if (showDndBanner) {
        return <DNDBannerWrapper />;
      }

      return <>{defaultBanner}</>;
    }, [defaultBanner, showDndBanner, connectionBanner]);

    return banner;
  }
);
