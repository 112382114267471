export const randomString = (length: number): string => {
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  return Array.from({ length }, () =>
    possible.charAt(Math.floor(Math.random() * possible.length))
  ).join('');
};

/**
 * Generates a random number between provided min and max numbers.
 * @param min Lowest number to be generated.
 * @param max Highest number to be generated.
 * @returns The generated number.
 */
export const randomNumberBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
