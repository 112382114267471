import { ErrorBanner } from 'Components/shared/ErrorBanner';
import { TextButton } from 'Components/shared/TextButton';
import { Channel as ChannelContainer } from 'Containers/Channel';
import { ContextPanelProps } from 'Containers/ContextPanel/interfaces';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import { useEffect } from 'react';
import { Outlet } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { ContentArea } from '../shared/ContentArea';

export default observer((props: ContextPanelProps) => {
  const { person, ui } = React.useContext<RootStoreProps>(MobXProviderContext);

  const handleCloseError = () =>
    person.setErrorNewContact({
      show: false,
      message: '',
      field: '',
    });

  useEffect(() => {
    const {
      allSources: sourceList,
      allContacts: contactList,
      loadingContacts,
      getAllContacts,
      getSourcesIfMissing,
    } = person;

    if (contactList.length === 0 && !loadingContacts) {
      void getAllContacts();
    }
    if (sourceList.length === 0) {
      void getSourcesIfMissing();
    }
  }, []);

  useEffect(() => {
    if (person.newContactErrors.show) {
      document.getElementById('addressBook-container')?.scrollTo(0, 0);
    }
  }, [person.newContactErrors.show]);

  const testid = `${props.testid}-addressBookPanel`;

  return (
    <ContentArea>
      <ErrorBanner
        show={person.newContactErrors.show}
        action={
          <TextButton
            variant="inverted"
            testid={`${testid}-errorBanner-buttonClose`}
            onClick={handleCloseError}
          >
            Close
          </TextButton>
        }
        content={person.newContactErrors.message}
      />
      <Grid.Column
        className="addressBook-container"
        id="addressBook-container"
        data-testid={testid}
      >
        <ChannelContainer
          openGroupModal={ui.groupModalOpen}
          {...props}
        />
        <Outlet />
      </Grid.Column>
    </ContentArea>
  );
});
