import { API_ENDPOINTS, IS_ELECTRON, RELEASE_ID } from 'Constants/env';
import API, { getBearerAuthToken } from '~/api';
import { getBrowserName } from '../getBrowser';
import getOs from '../getOS';
import { DEFAULT_CONFIG, STORAGE_KEY } from './index.contants';
import {
  ILogEntry,
  LoggerConfig,
  ILogMetadata,
  TLog,
  LoggerApiResponse,
} from './index.types';
/**
 * Logger class that handles client-side logging with persistent storage and periodic server sync.
 * @class
 */
export class Logger {
  private readonly sendIntervalMs: number;
  private readonly retryAttemptCount: number;
  private readonly retryDelay: number;
  private readonly metadata: ILogMetadata;

  private intervalTimer: ReturnType<typeof setInterval> | null = null;
  private isOnline = navigator.onLine;

  /**
   * Creates a new Logger instance.
   * @param {number} sendIntervalMs - Interval in milliseconds between log sync attempts (default: 5 minutes)
   * @param {number} retryAttemptCount - Number of retry attempts for failed sync (default: 3)
   * @param {number} retryDelay - Delay in milliseconds between retry attempts (default: 5000)
   */
  constructor(config: LoggerConfig = {}) {
    this.sendIntervalMs =
      config.sendIntervalMs ?? DEFAULT_CONFIG.SEND_INTERVAL_MS;
    this.retryAttemptCount =
      config.retryAttemptCount ?? DEFAULT_CONFIG.RETRY_ATTEMPTS;
    this.retryDelay = config.retryDelay ?? DEFAULT_CONFIG.RETRY_DELAY;
    this.metadata = {
      version: RELEASE_ID,
      browser: getBrowserName(navigator.userAgent),
      platform: IS_ELECTRON ? 'electron' : 'web',
      os: getOs(),
    };
    this.setupTimer();
    this.setupNetworkListener();
  }

  /**
   * Sets up the periodic timer for sending logs to the server.
   * @private
   */
  private setupTimer(): void {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
      this.intervalTimer = null;
    }

    this.intervalTimer = setInterval(() => {
      if (this.isOnline) {
        void this.sendLogs();
      }
    }, this.sendIntervalMs);
  }

  /**
   * Sets up network status listeners to handle online/offline states.
   * @private
   */
  private setupNetworkListener() {
    window.addEventListener('online', () => {
      this.isOnline = true;
      this.setupTimer();
    });
    window.addEventListener('offline', () => {
      this.isOnline = false;
    });
  }

  /**
   * Retrieves all stored logs from session storage.
   * @private
   * @returns {ILogEntry[]} Array of log entries
   */
  private getLogs(): ILogEntry[] {
    return JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '[]') as
      | ILogEntry[]
      | [];
  }

  /**
   * Stores a new log entry in session storage.
   * @private
   * @param {TLog} level - Log level ('debug', 'info', 'warn', 'error')
   * @param {string} message - Log message
   * @param {object | Error} [data] - Additional data or Error object to log
   */
  private storeLogs(level: TLog, message: string, data?: object | Error) {
    const logEntry: ILogEntry = {
      timestamp: new Date().toISOString(),
      level,
      message,
      data:
        data instanceof Error
          ? { message: data.message, stack: data.stack }
          : data,
    };

    const logs: ILogEntry[] = JSON.parse(
      sessionStorage.getItem(STORAGE_KEY) || '[]'
    ) as ILogEntry[] | [];

    if (logs?.length > DEFAULT_CONFIG.MAX_LOGS_COUNT) {
      logs.shift();
    }

    logs.unshift(logEntry);
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(logs));
  }

  /**
   * Clears all stored logs from session storage.
   * @private
   */
  private clearLogs() {
    sessionStorage.removeItem(STORAGE_KEY);
  }

  /**
   * Sends stored logs to the server with retry logic.
   * @private
   * @returns {Promise<void>}
   */
  private async sendLogs(): Promise<void> {
    const logs = this.getLogs();
    if (logs.length === 0) {
      return;
    }

    const logsAsStrings = logs.map((log) =>
      JSON.stringify({
        timestamp: log.timestamp,
        level: log.level,
        message: log.message,
        data: log.data,
      })
    );

    const data = {
      logs: logsAsStrings,
      metadata: this.metadata,
    };

    for (let attempt = 1; attempt <= this.retryAttemptCount; attempt++) {
      try {
        if (getBearerAuthToken()) {
          const res: LoggerApiResponse = await API.post(
            API_ENDPOINTS.Logger,
            data
          );

          if (res?.data?.data) {
            this.clearLogs();
            return;
          }
        }
      } catch (error) {
        if (attempt < this.retryAttemptCount) {
          await this.sleep(this.retryDelay * attempt);
        }
      }
    }
  }

  /**
   * Utility method to create a delay.
   * @private
   * @param {number} ms - Delay duration in milliseconds
   * @returns {Promise<void>}
   */
  private sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Logs a debug message.
   * @param {string} message - Debug message
   * @param {object | Error} [data] - Additional data or Error object
   */
  debug(message: string, data?: object | Error) {
    this.storeLogs('debug', message, data);
  }

  /**
   * Logs an info message.
   * @param {string} message - Info message
   * @param {object | Error} [data] - Additional data or Error object
   */
  info(message: string, data?: object | Error) {
    this.storeLogs('info', message, data);
  }

  /**
   * Logs a warning message.
   * @param {string} message - Warning message
   * @param {object | Error} [data] - Additional data or Error object
   */
  warn(message: string, data?: object | Error) {
    this.storeLogs('warn', message, data);
  }

  /**
   * Logs an error message.
   * @param {string} message - Error message
   * @param {object | Error} [data] - Additional data or Error object
   */
  error(message: string, data?: object | Error) {
    this.storeLogs('error', message, data);
  }

  /**
   * Cleans up the logger instance by clearing the sync interval.
   */
  destroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
  }
}

let loggerInstance: Logger | null = null;

/**
 * Gets or creates a singleton logger instance.
 * @param {number} [sendIntervalMs] - Interval in milliseconds between log sync attempts
 * @param {number} [retryAttemptCount] - Number of retry attempts for failed sync
 * @param {number} [retryDelay] - Delay in milliseconds between retry attempts
 * @returns {Logger} Logger instance
 */
export const getLogger = (
  sendIntervalMs?: number,
  retryAttemptCount?: number,
  retryDelay?: number
): Logger => {
  if (!loggerInstance) {
    loggerInstance = new Logger({
      sendIntervalMs,
      retryAttemptCount,
      retryDelay,
    });
  }
  return loggerInstance;
};

/**
 * Destroys the current logger instance if it exists.
 */
export const destroyLogger = () => {
  if (loggerInstance) {
    loggerInstance.destroy();
    loggerInstance = null;
  }
};
