import type { PollingConfig } from '../index.types';
import { useFetchMessages } from 'Modules/message/index.requests';

export const useMessagesPolling = ({
  pollingEnabled,
  intervalFunction,
}: PollingConfig) => {
  const { refetch: refetchMessages } = useFetchMessages({
    pollingEnabled,
    intervalFunction,
  });

  return { refetchMessages };
};

export default useMessagesPolling;
