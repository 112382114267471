import { SMALL_SCREEN_SIZE_BREAKPOINT } from 'Constants/responsiveness';
import {
  STORE_CONFIG,
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
} from 'Constants/stores';
import { TEST_CONVERSATION_PATH_START } from 'Constants/validation';
import ActionPanelIntentContainer from 'Containers/ActionPanelIntent';
import ActivityListContainer from 'Containers/ActivityList';
import { Channel as ChannelContainer } from 'Containers/Channel';
import withRouter from 'Hocs/WithRouter';
import { inject, MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { useLocation, useMatch } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { ActionPanelProps } from './interfaces';

const ActionPanel: React.FC<ActionPanelProps> = (props) => {
  const { person, phoneCall, ui } =
    React.useContext<RootStoreProps>(MobXProviderContext);

  const { pathname } = useLocation();
  const pathPrefix = TEST_CONVERSATION_PATH_START(pathname)
    ? '/conversations/:conversationId/*'
    : '/';
  const isChannelConversation = useMatch(
    `${pathPrefix}channel/:channelConversationId/:channelMode?`
  );
  if (!person.IsLoggedIn) {
    return null;
  }
  const activeCall = phoneCall.AnyPhoneConnectionActive;
  const largeScreen = activeCall || ui.openTopbarDialpad ? 12 : 15;
  const otherScreens = activeCall || ui.openTopbarDialpad ? 8 : largeScreen;

  return (
    <Grid.Column
      widescreen={otherScreens}
      computer={otherScreens}
      desktop={otherScreens}
      tablet={otherScreens}
      mobile={otherScreens}
      id="action-panel"
      className="flex-column"
      onMouseLeave={() =>
        ui.setHideSecondaryMenu(
          window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT
        )
      }
    >
      <ActionPanelIntentContainer />

      {isChannelConversation ? (
        <ChannelContainer />
      ) : (
        <ActivityListContainer {...props} />
      )}
    </Grid.Column>
  );
};

export default inject(
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_CONFIG,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
  STORE_MESSAGE,
  STORE_CONVERSATION
)(withRouter(observer(ActionPanel)));
