import type { LinkPreviewProps } from 'Components/Chat/ChatLayout/LinkPreview/index.types';
import { TIMEZONE_IDENTIFIER } from 'Constants/env';
import { isEmpty } from 'lodash';
import { action, computed, observable, makeObservable } from 'mobx';
import moment from 'moment-timezone';
import { CALL_DIRECTION, CALL_DISPOSITION } from '../constants/enums';

export interface IMessageModelChat {
  text: string;
  text_v2?: string;
  smsRelay?: IMessageModelSMSRelay;
}
export interface IMessageModelSMSRelay {
  smsId?: string;
  fromPhone?: string;
  toPhone?: string;
  sent?: string;
}
export interface IMessageModelSMS {
  text: string;
  smsId?: string;
  fromPhone?: string;
}

export interface IMessageModelConferencePersonId
  extends IMessageModelConference {
  personId: number;
}
export interface IMessageModelConference {
  id: string;
  sessionId?: string;
  adminId: number;
  provider?: string;
  phone?: string;
  url?: string;
  start?: string;
  end?: string;
  type?: string;
  recordingUrl?: string;
  recordingDuration?: string;
  attendees: IMessageModelConferenceAttendees[];
  hasRecording: boolean;
  duration: string;
  displayName: string;
}

export interface IMessageModelSystemEvent {
  eventType: string;
  reference: string;
  source: string;
  payload: {
    accountId?: number;
    personId?: number;
    conversationId?: string;
    conferenceId?: string;
    messageId?: string;
    url?: string;
    numberOfParticipants?: number;
    conferenceDuration?: string;
    conferenceRecorded?: boolean;
    participants?: { personId: number; phone: string }[];
    participantsAdded?: { personId: number; phone: string }[];
    participantsRemoved?: { personId: number; phone: string }[];
  };
}

export interface IMessageModelConferenceAttendees {
  id: string;
  personId?: string;
  phone?: string;
  name?: string;
  email?: string;
  ip?: string;
  start?: Date;
  end?: Date;
}

export interface IMessageModelCall {
  direction: CALL_DIRECTION;
  from: {
    friendlyName: string;
    phone: string;
    personId: number;
  };
  to: {
    friendlyName: string;
    phone: string;
    personId: number;
  };
  callId: string;
  start: string;
  bridged: string;
  end: string;
  totalDuration: string;
  callDuration: string;
  disposition: CALL_DISPOSITION;
}

export interface IMessageModel {
  id: string;
  /** External FK, mutually exclusive with `phone` */
  personId?: number;
  /** Mutually exclusive with `personId` */
  phone?: string;
  created: string;
  updated?: string;
  chat?: IMessageModelChat;
  documents?: IMessageDocument[];
  sms?: IMessageModelSMS;
  call?: IMessageModelCall;
  conference?: IMessageModelConference;
  systemEvent?: IMessageModelSystemEvent;
  isDeleted?: boolean;
  references?: IMessageReferences[];

  /** Marker for a message received from Pusher, allowing for different behavior if needed. */
  __isPush?: boolean;
}

export type IMessageReferences = {
  id: string;
  type: 'reply';
  snapshot: {
    chat: IMessageModelChat;
    created: string;
    documents?: IMessageDocument[];
    personId?: number;
  };
};

export interface IMessageLinkPreview {
  url: string;
  image?: string;
  title?: string;
  description?: string;
}

export interface IMessageLinkPreviewDimensions {
  /** Height of the entire `<LinkPreview />` */
  width: number;
  /** Width of the entire `<LinkPreview />` */
  height: number;
  /** Height of the image within the `<LinkPreview />` */
  imageWidth: number;
  /** Width of the image within the `<LinkPreview />` */
  imageHeight: number;
  imageLoadFailed?: boolean;
}

export interface IMessageDocument {
  externalId: string;
  contentType?: string;
  description?: string;
  byteCount?: number;
  created?: string;
  isDeleted: boolean;
  url: string;
  hasPreview?: boolean;
  previewContentType?: string;
  previewUrl?: string;
  previewHeight?: number;
  previewWidth?: number;
  name?: string;
}

export interface IMessageModelConstructorProps {
  id: string;
  created: string;
  updated?: string;
  personId?: number;
  phone?: string;
  chat?: IMessageModelChat;
  documents?: IMessageDocument[];
  sms?: IMessageModelSMS;
  call?: IMessageModelCall;
  conference?: IMessageModelConference;
  systemEvent?: IMessageModelSystemEvent;
  isDeleted?: boolean;
  isPush?: boolean;
  references?: IMessageReferences[];
}

export class MessageModel implements IMessageModel {
  @observable
  public id: string;

  @action
  setId = (id: string) => (this.id = id);

  /** External FK, mutually exclusive with `phone` */
  public personId?: number;
  /** Mutually exclusive with `personId` */
  public phone?: string;

  public isFirstIn5Mins?: boolean;

  /** Created Time ISO8601 UTC */
  @observable
  public created: string;

  /** Set the Created Time ISO8601 UTC */
  @action
  public setCreated = (created: string) => (this.created = created);

  /** Set the smsRelay if it exists */
  @action
  public setSMSRelay = (smsRelay: IMessageModelSMSRelay) => {
    this.chat.smsRelay = smsRelay;
  };

  @observable
  public isRead: boolean;

  @action
  setIsRead = (isRead: boolean) => (this.isRead = isRead);

  /** Zoned `moment` representation of `created` (zoned to `TIMEZONE_IDENTIFIER`) */
  @computed
  get CreatedMoment() {
    return moment.tz(this.created, TIMEZONE_IDENTIFIER);
  }

  public updated: string;
  @action
  setUpdated = (updated: string) => (this.updated = updated);

  public isDeleted?: boolean;
  @action
  setDeleted = (deleted: boolean) => (this.isDeleted = deleted);

  @observable
  public chat?: IMessageModelChat;

  @action
  setChat = (chat: IMessageModelChat) => (this.chat = chat);

  @observable
  public sms?: IMessageModelSMS;

  @action
  setSms = (sms: IMessageModelSMS) => (this.sms = sms);

  @observable
  public call?: IMessageModelCall;

  @observable
  public documents: IMessageDocument[];

  @action
  setDocuments = (documents: IMessageDocument[]) =>
    (this.documents = documents);

  @action
  setDocument = (externalId: string) => {
    const filteredData = this.documents.reduce((prevVal, document) => {
      if (document.externalId === externalId) {
        prevVal.push({ ...document, isDeleted: true });
        return prevVal;
      }
      prevVal.push(document);
      return prevVal;
    }, []);
    this.setDocuments(filteredData);
  };

  @action
  setCall = (call: IMessageModelCall) => (this.call = call);

  @observable
  public conference?: IMessageModelConference;

  @action
  setConference = (conference: IMessageModelConference) =>
    (this.conference = conference);

  @observable
  public references?: IMessageReferences[];

  @action
  setReferences = (references: IMessageReferences[]) =>
    (this.references = references);

  @observable
  public systemEvent?: IMessageModelSystemEvent;

  @action
  setSystemEvent = (systemEvent: IMessageModelSystemEvent) =>
    (this.systemEvent = systemEvent);

  //
  // --- *UI only properties, which do not correspond to API DTO responses ---
  //
  @observable
  public showKeyPad = false;

  @action
  setShowKeyPad = (showKeyPad: boolean) => (this.showKeyPad = showKeyPad);

  @action
  toggleShowKeyPad = () => (this.showKeyPad = !this.showKeyPad);

  @observable
  public userNumberInput = '';

  @action
  appendUserNumberInput = (inputKey: string) =>
    (this.userNumberInput += inputKey);

  @action
  deleteUserNumberInput = () =>
    (this.userNumberInput = this.userNumberInput.slice(
      0,
      this.userNumberInput.length - 1
    ));

  @observable
  public showIcon = false;

  @action
  setShowIcon = (showIcon: boolean) => (this.showIcon = showIcon);

  @action
  toggleShowIcon = () => (this.showIcon = !this.showIcon);

  @observable
  linkPreview: LinkPreviewProps = null;

  @action
  setLinkPreview = (obj: LinkPreviewProps) => {
    if (isEmpty(obj)) {
      this.linkPreview = null;
    } else {
      this.linkPreview = obj;
    }
  };

  @observable
  linkPreviewDimensions: IMessageLinkPreviewDimensions = null;

  @action
  setLinkPreviewImageDimensions = (
    dimensions: IMessageLinkPreviewDimensions
  ) => {
    if (isEmpty(dimensions)) {
      this.linkPreviewDimensions = null;
    } else {
      this.linkPreviewDimensions = dimensions;
    }
  };

  //
  // *--- End UI only properties, which do not correspond to API DTO responses ---
  //

  private _createdDate: Date;
  public get CreatedAtDate() {
    return this._createdDate || (this._createdDate = new Date(this.created));
  }

  @action
  setCreatedAtDate = (createdAtDate: Date) =>
    (this._createdDate = createdAtDate);

  /**
   * Marker for a message received from Pusher, allowing for different behavior if needed.
   * _This field should be filtered out of API requests._
   */
  public __isPush = false;

  constructor({
    id,
    created,
    updated,
    personId,
    phone,
    chat,
    documents,
    sms,
    call,
    conference,
    systemEvent,
    isDeleted,
    isPush,
    references,
  }: IMessageModelConstructorProps) {
    makeObservable(this);
    if (personId && phone) {
      this.personId = personId;
      this.phone = null;
    } else {
      this.personId = personId;
      this.phone = phone;
    }
    if (
      (!chat &&
        !call &&
        !sms &&
        !conference &&
        !systemEvent &&
        !documents &&
        isDeleted !== true) ||
      (chat && call && sms && conference && documents && systemEvent)
    ) {
      throw Error(
        "Either a chat, call, conference, or event is required, not all can be provided, and all can't be null/empty/0."
      );
    }
    this.id = id;
    this.created = created;
    this.updated = updated;
    this._createdDate = new Date(this.created);
    this.chat = chat;
    this.sms = sms;
    this.call = call;
    this.documents = documents;
    this.conference = conference;
    this.systemEvent = systemEvent;
    this.isDeleted = isDeleted;
    this.__isPush = isPush;
    this.references = references;
  }

  static FromResponseDto(responseDto: IMessageModel) {
    return new MessageModel({
      id: responseDto.id,
      created: responseDto.created,
      updated: responseDto.updated,
      personId: responseDto.personId,
      phone: responseDto.phone,
      chat: responseDto.chat,
      documents: responseDto.documents,
      sms: responseDto.sms,
      call: responseDto.call,
      conference: responseDto.conference,
      systemEvent: responseDto.systemEvent,
      isDeleted: responseDto.isDeleted,
      isPush: null,
      references: responseDto.references,
    });
  }
}

export default MessageModel;
