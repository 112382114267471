import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { BannerStoreState, BannerStore } from './index.type';

const initialState: BannerStoreState = {
  showConnectionBanner: false,
  toleranceTimeSurpassed: false,
};

const bannerStore = create(
  devtools<BannerStore>((set) => ({
    ...initialState,
    setShowConnectionBanner(showConnectionBanner) {
      set((state) => ({
        ...state,
        showConnectionBanner,
      }));
    },
    setToleranceTimeSurpassed(toleranceTimeSurpassed) {
      set((state) => ({
        ...state,
        toleranceTimeSurpassed,
      }));
    },
    reset: () => set(initialState),
  }))
);

export const useBannerStore = bannerStore;
