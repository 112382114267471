/* eslint-disable  */
import 'emoji-mart/css/emoji-mart.css';

import * as React from 'react';

import { Container, Grid } from 'semantic-ui-react';
import { MobXProviderContext, inject, observer } from 'mobx-react';
import { QUERY_KEY, fetchPreferences } from 'Hooks/preferences/usePreferences'; // Import the hook
import {
  STORE_MESSAGE,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
} from 'Constants/stores';
import { useLocation, useNavigate } from 'react-router';

import { IAppProps } from './interfaces';
import { KEYCODES } from 'Constants/env';
import Layout from 'Containers/Layout';
import { RootStoreProps } from 'Stores/RootStore.types';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from 'Constants/responsiveness';
import { TopBar } from 'Components/TopBar';
import { UseQuery } from 'Hocs/UseQuery';
import iconConnectionError from 'Assets/images/icon-connection-error.svg';
import iconWarning from 'Assets/images/icon-warning.svg';
import { withErrorHandler } from 'Hocs/ErrorHandlerMixin';

const spinner = require('Assets/images/spinner-1s-200px.gif');

const NavigationListener = observer(() => {
  const { router } = React.useContext<RootStoreProps>(MobXProviderContext);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    router.setReactRouterLocation(location);
  }, [location]);

  React.useEffect(() => {
    if (router.requestedNavigationTo) {
      navigate(router.requestedNavigationTo);
      router.requestedNavigationTo = null;
    } else if (router.requestedRedirectionTo) {
      navigate(router.requestedRedirectionTo, { replace: true });
      router.requestedRedirectionTo = null;
    }
    router.reset();
  }, [router.requestedNavigationTo, router.requestedRedirectionTo]);

  return null; // This component doesn't render anything
});

class App extends React.Component<
  IAppProps,
  { error?: any; hideSecondaryMenu: boolean }
> {
  static displayName = 'App';
  state = {
    error: undefined,
    hideSecondaryMenu: false,
  };

  renderDevTool = () => {
    if (process.env.FORCE_MOBX_DEVTOOLS) {
      const DevTools = require('mobx-react-devtools').default;
      return <DevTools position={{ top: 0, left: 200 }} />;
    }
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    console.error(
      'Uncaught Error from React component tree:',
      error,
      errorInfo
    );
  }

  iconImages = [iconWarning, spinner, iconConnectionError];

  componentDidMount() {
    this.iconImages.map((images) => {
      const img = document.createElement('img');
      img.src = images;
    });
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    if (window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT)
      this.setState({ hideSecondaryMenu: true });
    else this.setState({ hideSecondaryMenu: false });
  };

  onContainerClick = () => {
    const { message } = this.props;
    if (message.createMessageMentionListOpen) {
      message.setCreateMessageMentionListOpen(false);
    }
    if (message.editMessageMentionListOpen) {
      message.setEditMessageMentionListOpen(false);
    }
  };

  onContainerKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const { message } = this.props;
    if (e.keyCode === KEYCODES.ESCAPE) {
      if (message.createMessageMentionListOpen) {
        message.setCreateMessageMentionListOpen(false);
      }
      if (message.editMessageMentionListOpen) {
        message.setEditMessageMentionListOpen(false);
      }
    }
  };

  render() {
    return (
      <UseQuery queryKey={[QUERY_KEY]} queryFn={fetchPreferences}>
        {({ isSuccess }) =>
          isSuccess && (
            <Container
              fluid
              className="app-container"
              onClick={this.onContainerClick}
              onKeyDown={this.onContainerKeyDown}
            >
              <NavigationListener />
              <TopBar />
              <Grid
                className={`main-grid ${
                  this.state.hideSecondaryMenu && 'hide-secondary-menu'
                }`}
              >
                <Layout {...this.props} />
              </Grid>
            </Container>
          )
        }
      </UseQuery>
    );
  }
}
export const AppWithErrorHandler = inject(
  STORE_MESSAGE,
  STORE_PERSON,
  STORE_PREFERENCE,
  STORE_UI,
  STORE_PHONE_CALL
)(withErrorHandler(observer(App)));
