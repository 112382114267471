import type { TopBarDropdownActionButtonsProps } from './TopBarDropdownActionButtons.types';
import topBarCallIcon from 'Assets/images/top-bar-call.svg';
import topBarVideoIcon from 'Assets/images/top-bar-video.svg';
import groupIcon from 'Assets/images/user-group.svg';
import { TopBarDropdownContext } from 'Components/TopBar/contexts/TopBarDropdownContext';
import { MobXProviderContext, observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { Styled } from './TopBarDropdownActionButtons.styles';

export const TopBarDropdownActionButtons: React.FC<TopBarDropdownActionButtonsProps> =
  observer(({ activeConversationId, setActiveConversationId }) => {
    const {
      ui: { setGroupModal, groupModalOpen, setOpeningGroupModalFrom },
      conversation: {
        postConferenceByConversationId,
        loadOrCreateConversationWithPost,
        createConversationFromAdHoc,
        setChannelInfoDetails,
        LoggedInUserActiveConferenceConversation,
      },
      person: { personAvaliableFeatures },
    } = React.useContext<RootStoreProps>(MobXProviderContext);
    const { selection, makeCall } = React.useContext(TopBarDropdownContext);

    const navigate = useNavigate();

    const makeVideoCall = (e) => {
      if (selection.users.length === 1) {
        e?.stopPropagation();
        void loadOrCreateConversationWithPost(selection.users[0]).then(
          ({ data }) => {
            navigate(`/chat/conversations/${data.id}/menu`);
            postConferenceByConversationId(data.id.toString());
          }
        );
        return;
      }

      activeConversationId
        ? postConferenceByConversationId(activeConversationId)
        : createConversationFromAdHoc(selection.users, '').then(
            (newConversation) => {
              if (!newConversation) return;
              const newConversationId = newConversation.id.toString();
              setActiveConversationId(newConversationId);
              postConferenceByConversationId(newConversationId);
            }
          );
    };

    const isVideoCallDisabled = useMemo(
      () =>
        !!LoggedInUserActiveConferenceConversation ||
        !personAvaliableFeatures.video.enabled,
      [
        LoggedInUserActiveConferenceConversation,
        personAvaliableFeatures.video.enabled,
      ]
    );

    return (
      <Styled.TopBarDropdownButtonWrapper>
        {selection.users?.length === 1 && (
          <Styled.CustomButton
            onClick={(e) =>
              void makeCall(
                selection.variant === 'internal-contact'
                  ? { personId: selection.users[0] }
                  : { phoneNumbers: selection.users },
                e
              )
            }
          >
            <img src={topBarCallIcon} />
          </Styled.CustomButton>
        )}
        {selection.variant === 'internal-contact' && (
          <>
            <Styled.CustomButton
              {...(isVideoCallDisabled
                ? { className: 'disabled-button cursor-not-allowed' }
                : { onClick: makeVideoCall })}
            >
              <img src={topBarVideoIcon} />
            </Styled.CustomButton>
            <Styled.CustomButton
              onClick={() => {
                setGroupModal(!groupModalOpen);
                setChannelInfoDetails('0', 'new', 'person');
                setOpeningGroupModalFrom('top-bar');
              }}
            >
              <img src={groupIcon} />
            </Styled.CustomButton>
          </>
        )}
      </Styled.TopBarDropdownButtonWrapper>
    );
  });
