import type { TopBarDropdownContextProps } from '../contexts/TopBarDropdownContext';
import { DropDownItemTopBar } from 'Components/TopBar/DropDownItemTopBar';
import { isObservable } from 'mobx';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { bugsnagClient } from 'Utils/logUtils';
import { createArrayOfPersonIds } from '../helpers/createArrayOfPersonIds';

export type useFavoriteOptionsProps = () => {
  mapFavoritesOptions: TopBarDropdownContextProps['mapFavoritesOptions'];
  favoriteList: TopBarDropdownContextProps['favoriteList'];
};

export const useFavoriteOptions: useFavoriteOptionsProps = () => {
  const {
    contact: { loadContactByPhoneNumber },
    conversation,
    participant: { selectParticipantsByConversationId },
    person: {
      loggedInPersonId,
      getExtrContactByPhoneNumber,
      loadPersonByIdGetIfMissingGet,
    },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const [favoriteList, setFavoriteList] = React.useState<Record<string, true>>(
    {}
  );

  const mapFavoritesOptions: TopBarDropdownContextProps['mapFavoritesOptions'] =
    (conversationList) => {
      const allUsersInFav = [];
      const favoritesOptions: TopBarDropdownContextProps['options'] =
        conversationList.map((conv) => {
          if (!conv) return null;

          if (conv.grouping === 'Channel' || conv.grouping === 'Group') {
            const adHocGrpName = conversation.changeConvTopic(
              createArrayOfPersonIds(conv.participants)
            );
            allUsersInFav.push(conv);
            return {
              key: conv.id.toString(),
              value: conv.id.toString(),
              text: conv.topic || adHocGrpName,
              label: (
                <DropDownItemTopBar
                  favorite
                  ignoreName
                  name={conv.topic || adHocGrpName}
                  convType="Channel"
                  variant="group"
                />
              ),
              variant: 'group',
            };
          }

          const participants = selectParticipantsByConversationId(conv?.id);

          if (
            !participants ||
            !isObservable(participants) ||
            typeof participants.case !== 'function'
          ) {
            bugsnagClient.leaveBreadcrumb(
              `No observable participants found for observable conversation case with ${conv.id}`
            );
            return null;
          }

          return participants.case({
            fulfilled: (resp) => {
              if (!resp?.data?.results?.length) {
                bugsnagClient.leaveBreadcrumb(
                  `No participants found for observable conversation case with ${conv.id}`
                );
                return null;
              }

              const currentParticipants = resp.data.results;
              const otherParticipant = currentParticipants.find(
                ({ personId }) => personId !== loggedInPersonId
              );

              if (!otherParticipant) {
                bugsnagClient.leaveBreadcrumb(
                  `No other participant found for observable conversation case with ${conv.id}`
                );
                return null;
              }

              if (otherParticipant.personId) {
                const personData = loadPersonByIdGetIfMissingGet(
                  otherParticipant.personId
                );

                if (
                  !personData ||
                  !isObservable(personData) ||
                  typeof personData.case !== 'function'
                ) {
                  bugsnagClient.leaveBreadcrumb(
                    `No observable person found for observable conversation case with ${conv.id}`
                  );
                  return null;
                }

                return personData.case({
                  fulfilled: ({ data: otherPerson }) => {
                    if (!otherPerson) {
                      bugsnagClient.leaveBreadcrumb(
                        `No other person found for observable conversation case with ${conv.id}`
                      );
                      return null;
                    }

                    allUsersInFav.push(otherPerson);
                    return {
                      key: otherPerson.id.toString(),
                      value: otherPerson.id.toString(),
                      text: otherPerson.DisplayName,
                      label: (
                        <DropDownItemTopBar
                          favorite
                          userInfo={{
                            personId: otherPerson.id.toString(),
                            phoneNumbers: null,
                            keyValue: otherPerson.id.toString(),
                          }}
                          name={otherPerson.DisplayName}
                          organization={otherPerson.department}
                          role={otherPerson.role}
                          variant="internal-contact"
                        />
                      ),
                      variant: 'internal-contact',
                    };
                  },
                });
              }

              if (!otherParticipant.phone) {
                bugsnagClient.leaveBreadcrumb(
                  `No phone number found for participant for observable conversation case with ${conv.id}`
                );
                return null;
              }

              const extrContact = getExtrContactByPhoneNumber(
                otherParticipant.phone
              );
              if (extrContact) {
                allUsersInFav.push(extrContact);
                return {
                  key: extrContact.uuid,
                  value: extrContact.uuid,
                  text: extrContact.DisplayName(),
                  variant: 'external-contact',
                  phoneNumber: extrContact.phoneNumbers[0]?.number,
                  label: (
                    <DropDownItemTopBar
                      favorite
                      userInfo={{
                        phoneNumbers: extrContact.phoneNumbers.map(
                          ({ number }) => number
                        ),
                        keyValue: extrContact.uuid,
                      }}
                      name={extrContact.DisplayName()}
                      organization={extrContact.organization}
                      pictureUrl={extrContact.pictureUrl}
                      role={extrContact.organizationRole}
                      variant="external-contact"
                    />
                  ),
                };
              }

              const contactPbo = loadContactByPhoneNumber(
                otherParticipant.phone
              );
              if (
                !contactPbo ||
                !isObservable(contactPbo) ||
                typeof contactPbo.case !== 'function'
              ) {
                bugsnagClient.leaveBreadcrumb(
                  `No observable contact found for observable conversation case with ${conv.id}`
                );
                return null;
              }

              return contactPbo.case({
                fulfilled: (resp) => {
                  if (!resp?.data) {
                    bugsnagClient.leaveBreadcrumb(
                      `No contact found for observable conversation case with ${conv.id}`
                    );
                    return null;
                  }

                  const contact = resp.data;
                  allUsersInFav.push(contact);
                  return {
                    key: contact.id.toString(),
                    value: contact.id.toString(),
                    text: contact.DisplayName,
                    variant: 'external-contact',
                    phoneNumber: contact.phoneNumber,
                    label: (
                      <DropDownItemTopBar
                        favorite
                        ignoreName
                        userInfo={{
                          phoneNumbers: [contact.phoneNumber],
                          keyValue: contact.id.toString(),
                        }}
                        name={contact.DisplayName}
                        variant="external-contact"
                      />
                    ),
                  };
                },
              });
            },
          });
        });

      const favoritesById = {};
      favoritesOptions.forEach((favorite) => {
        if (!favorite) return;
        favoritesById[favorite.key] = true;
      });

      setFavoriteList(favoritesById);
      return favoritesOptions.filter(Boolean) || [];
    };

  return { favoriteList, mapFavoritesOptions };
};
