import type { ActivityItemPresenceDisplayProps } from './interfaces';
import groupChatIcon from 'Assets/images/group-chat.svg';
import removedUser from 'Assets/images/removed-user.svg';
import cx from 'classnames';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { STORE_CONTACT } from 'Constants/stores';
import { usePreferences } from 'Hooks/preferences/usePreferences';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Styled } from './index.styles';

/**
 * Named export is not an observer
 */
const ActivityItemPresenceDisplay: React.FC<
  ActivityItemPresenceDisplayProps
> = ({
  conversationGrouping,
  conversationId = '0',
  personId,
  personPbo,
  selectPersonPresenceStatus,
  selectUnreadCounts,
  setConversationAndTotalUnreadCount,
  showUnread = false,
  updateMyLastReadMessage,
  getExtrContactByPhoneNumber,
  phoneNumber,
  contact: { getContactByPhoneNumber },
}) => {
  const unreadCounts = selectUnreadCounts(conversationId);
  const {
    preferences: { directMentionsOnly },
  } = usePreferences();

  const getDisplayIcon = () => {
    if (!showUnread) {
      return (
        <Styled.Avatar>
          <img src={groupChatIcon} alt="group-icon" />
        </Styled.Avatar>
      );
    }

    const unreadNumber = directMentionsOnly
      ? unreadCounts.unreadMentions
      : unreadCounts.unreadMessages;

    if (unreadNumber === 0) {
      return (
        <Styled.Avatar>
          <img src={groupChatIcon} alt="group-icon" />
        </Styled.Avatar>
      );
    }

    const isOver99 = unreadNumber > 99;
    const displayNumber = isOver99 ? (
      <span>
        99<div className="superscript">+</div>
      </span>
    ) : (
      unreadNumber
    );

    return (
      <Styled.Avatar>
        <div className={cx('number-circle', { 'over-99': isOver99 })}>
          {displayNumber}
        </div>
        <img src={groupChatIcon} alt="group-icon" />
      </Styled.Avatar>
    );
  };

  const displayIcon = getDisplayIcon();

  if (personPbo) {
    return personPbo.case({
      pending: () => displayIcon,
      rejected: () =>
        conversationGrouping === 'Channel' && (
          <div className="removed-user">
            R U
            <img src={removedUser} className="presence-badge" />
          </div>
        ),
      fulfilled: (p) => {
        if (personId) {
          if (
            conversationGrouping !== 'Channel' &&
            conversationGrouping !== 'Group'
          ) {
            const presence = selectPersonPresenceStatus(personId);
            return (
              <UserAvatar
                name={p.data.DisplayName}
                showUnread={showUnread}
                conversationId={conversationId}
                presence={presence}
                selectUnreadCounts={selectUnreadCounts}
                setConversationAndTotalUnreadCount={
                  setConversationAndTotalUnreadCount
                }
                updateMyLastReadMessage={updateMyLastReadMessage}
                key={`action-panel-avatar-${p.data.id}-${presence?.state}`}
              />
            );
          } else {
            return (
              <div className="list-item-icon-wrapper">
                {displayIcon}
                {unreadCounts.unreadMentions > 0 &&
                  unreadCounts.unreadMessages > 0 && (
                    <div className="presence-badge mention">@</div>
                  )}
              </div>
            );
          }
        } else {
          return (
            <UserAvatar
              name={p.data.DisplayName}
              selectUnreadCounts={selectUnreadCounts}
              presence={selectPersonPresenceStatus(0)}
              setConversationAndTotalUnreadCount={
                setConversationAndTotalUnreadCount
              }
              updateMyLastReadMessage={updateMyLastReadMessage}
            />
          );
        }
      },
    });
  }

  const contactByPhone = getContactByPhoneNumber(phoneNumber);

  const extrContact =
    contactByPhone &&
    getExtrContactByPhoneNumber(contactByPhone.data.phoneNumber);

  const displayName = extrContact
    ? extrContact.DisplayName()
    : contactByPhone?.data?.DisplayName || '';

  return (
    <div className="phone-user">
      {showUnread && unreadCounts.unreadMessages > 0 && (
        <div
          className={cx('number-circle', {
            'over-99': unreadCounts.unreadMessages > 99,
          })}
        >
          {unreadCounts.unreadMessages <= 99 ? (
            unreadCounts.unreadMessages
          ) : (
            <span>
              99<div className="superscript">+</div>
            </span>
          )}
        </div>
      )}
      <UserAvatar
        {...(contactByPhone && {
          image: extrContact?.pictureUrl,
          name: extrContact && displayName,
        })}
        {...{
          selectUnreadCounts,
          setConversationAndTotalUnreadCount,
          updateMyLastReadMessage,
        }}
      />
    </div>
  );
};

export default inject(STORE_CONTACT)(observer(ActivityItemPresenceDisplay));
