import downloadIcon from 'Assets/images/download-white.svg';
import {
  DOWNLOAD_APP_URL_MAC,
  DOWNLOAD_APP_URL_WINDOWS,
  INTERCOM_SETTINGS,
  IS_ELECTRON,
} from 'Constants/env';
import * as React from 'react';
import { Button, Grid } from 'semantic-ui-react';

import * as intercom from '../../utils/intercomWrapper';

const ActivityListActionButtons = () => {
  const downloadApp = () => {
    const macosPlatforms = [
      'Macintosh',
      'MacIntel',
      'MacPPC',
      'Mac68K',
      'darwin',
    ];
    const url = macosPlatforms.includes(window.navigator.platform)
      ? DOWNLOAD_APP_URL_MAC
      : DOWNLOAD_APP_URL_WINDOWS;
    window.open(url, '_blank');
  };

  const showFeedback = () => {
    intercom.startSurvey(Number(INTERCOM_SETTINGS.feedbackSurveyId));
  };

  return (
    <Grid.Column className="flex-row feedback-rate">
      <Button
        id="open-feedback"
        content="Feedback"
        className="flex-grow-shrink"
        onClick={showFeedback}
      />
      {!IS_ELECTRON && (
        <Button
          id="download-app"
          className="flex-grow-shrink"
          onClick={downloadApp}
        >
          <img src={downloadIcon} />
          <span className="flex-row flex-justify-center">Download App</span>
        </Button>
      )}
    </Grid.Column>
  );
};
export { ActivityListActionButtons };
