import { VOICEMAIL_URL } from 'Constants/env';
import { AuthenticatedIframeContainer } from 'Containers/IframeContainer';
import React from 'react';
import { RouteObject } from 'react-router';

const voicemailRoutes: RouteObject = {
  path: 'voicemail',
  element: <AuthenticatedIframeContainer src={VOICEMAIL_URL} />,
  handle: { title: 'Voicemail' },
};

export default voicemailRoutes;
