import type { ChatLayoutHeaderMoreOptionsProps } from './ChatLayoutHeaderMoreOptions.types';
import { PopOverMenuOption } from 'Components/shared/PopOverMenuOption';
import { MobXProviderContext, observer } from 'mobx-react';
import React, { useCallback } from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { ConfirmationModalContext } from '../contexts/ConfirmationModalContext';
import { useMuteConversation } from '../hooks/useMuteConversation';

export const ChatLayoutHeaderMoreOptions: React.FC<ChatLayoutHeaderMoreOptionsProps> =
  observer(
    ({
      conversationId,
      isGroupChat,
      isAdHocGroupChat,
      personDetails,
      setShowDropdown,
      testid,
    }) => {
      const {
        conversation: { setChannelInfoDetails, CurrentConversation },
        ui: {
          setOpenedRightSidebarsOrder,
          groupModalOpen,
          setGroupModal,
          setSelectedTopBarUsers,
          setOpeningGroupModalFrom,
          removeFromOpenedRightSidebarsOrder,
        },
        participant: { removeParticipantsDelete },
        person: { loggedInPersonId, setShowPersonDetails, getUserProfile },
      } = React.useContext<RootStoreProps>(MobXProviderContext);
      const { setShow } = React.useContext(ConfirmationModalContext);
      const { isMuted, conversationMuteToggle } = useMuteConversation({
        conversationId,
      });

      const openInfoDetails = () => {
        setShowPersonDetails(null, null);
        personDetails &&
          setShowPersonDetails(personDetails.id, personDetails.type);
        setChannelInfoDetails(conversationId, isGroupChat ? 'channel' : 'new');
        removeFromOpenedRightSidebarsOrder('sidebar-info');
        setOpenedRightSidebarsOrder('sidebar-info');
      };

      const showLeaveGroupModal = useCallback(() => {
        const onConfirm = async () => {
          void removeParticipantsDelete(conversationId, [
            {
              id: `${loggedInPersonId}_`,
              name: (await getUserProfile()).DisplayName,
            },
          ]);
        };
        setShow({ type: 'leaveGroup', onConfirm });
      }, [conversationId, loggedInPersonId, removeParticipantsDelete, setShow]);

      const createGroupChat = () => {
        setGroupModal(!groupModalOpen);
        setChannelInfoDetails('0', 'new', 'person');
        setSelectedTopBarUsers([`${personDetails.id}`]);
        setOpeningGroupModalFrom('top-bar');
      };

      const closeOnOptionClick = useCallback(
        async (callback: () => void | Promise<void>) => {
          await callback();
          setShowDropdown(false);
        },
        [setShowDropdown]
      );

      const LeaveGroup = useCallback(
        () =>
          (isGroupChat && !isAdHocGroupChat) ||
          (isAdHocGroupChat &&
            CurrentConversation?.participants?.length > 2) ? (
            <PopOverMenuOption
              testid={`${testid}-buttonLeaveGroup`}
              onClick={() => closeOnOptionClick(showLeaveGroupModal)}
            >
              Leave Group
            </PopOverMenuOption>
          ) : null,

        [
          CurrentConversation?.participants?.length,
          closeOnOptionClick,
          isAdHocGroupChat,
          isGroupChat,
          showLeaveGroupModal,
          testid,
        ]
      );

      return (
        <>
          <PopOverMenuOption
            testid={`${testid}-buttonViewChatInfo`}
            onClick={() => closeOnOptionClick(openInfoDetails)}
          >
            {isGroupChat ? 'View group info' : 'View contact info'}
          </PopOverMenuOption>
          {CurrentConversation?.isActiveParticipant && (
            <>
              <PopOverMenuOption
                testid={`${testid}-buttonMuteNotifications`}
                onClick={() => closeOnOptionClick(conversationMuteToggle)}
              >
                {isMuted ? 'Unmute notifications' : 'Mute notifications'}
              </PopOverMenuOption>
              <LeaveGroup />
              {!isGroupChat && personDetails.type === 'person' && (
                <PopOverMenuOption
                  testid={`${testid}-buttonCreateGroupChat`}
                  onClick={() => closeOnOptionClick(createGroupChat)}
                >
                  Start a group chat
                </PopOverMenuOption>
              )}
            </>
          )}
        </>
      );
    }
  );
