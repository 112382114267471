// Types
interface VersionInfo {
  raw: string;
  formatted: string | null;
}

interface VersionResult {
  desktop: VersionInfo;
}

// Constants
const VERSION_PATTERNS = {
  desktop: /bhive-communicator-electron\/(\d+\.\d+\.\d+)/,
} as const;

const VERSION_CONFIG = {
  SEGMENT_LENGTH: 3,
  PAD_CHAR: '0',
  EXPECTED_SEGMENTS: 3,
} as const;

/**
 * Formats a version string into padded format (e.g., "1.2.91" -> "001002091")
 */
const formatVersion = (version: string): string | null => {
  if (!version || version === 'unknown') {
    return null;
  }

  const segments = version.split('.');

  if (segments.length !== VERSION_CONFIG.EXPECTED_SEGMENTS) {
    return null;
  }

  if (!segments.every((segment) => /^\d+$/.test(segment))) {
    return null;
  }

  return segments
    .map((segment) =>
      segment.padStart(VERSION_CONFIG.SEGMENT_LENGTH, VERSION_CONFIG.PAD_CHAR)
    )
    .join('');
};

/**
 * Extracts a version string from user agent using a specific pattern
 */
const extractVersion = (userAgent: string, pattern: RegExp): string => {
  const match = userAgent.match(pattern);
  return match ? match[1] : 'unknown';
};

/**
 * Creates a VersionInfo object from a raw version string
 */
const createVersionInfo = (version: string): VersionInfo => ({
  raw: version,
  formatted: formatVersion(version),
});

/**
 * Extracts and processes versions from a user agent string
 */
export const extractVersions = (userAgent: string): VersionResult => {
  const electronVersion = extractVersion(userAgent, VERSION_PATTERNS.desktop);

  return {
    desktop: createVersionInfo(electronVersion),
  };
};
