import cx from 'classnames';
import CallActive from 'Components/CallActive';
import CallHoldingList from 'Components/CallHoldingList';
import CallIncomingList from 'Components/CallIncomingList';
import { TransferDirectory } from 'Components/TransferDirectory';
import TransferHoldingList from 'Components/TransferHoldingList';
import {
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PUSHER,
  STORE_UI,
} from 'Constants/stores';
import { useNetworkStatus } from 'Hooks/network';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { Button, Image } from 'semantic-ui-react';
import IncomingVideoCall from '../../components/IncomingVideoCall';
import { Styled } from './index.styles';
import { ICallSidebarProps } from './interface';
const iconConnectionError = require('../../assets/images/icon-connection-error.svg');
const iconWarning = require('../../assets/images/icon-warning.svg');
const spinner = require('../../assets/images/spinner-1s-200px.gif');

const CallSidebar = (props: ICallSidebarProps) => {
  const {
    participant,
    person,
    phoneCall: phoneStore,
    ui,
    conversation,
    isFixed,
  } = props;

  const { isOnline } = useNetworkStatus();
  const incomingHoldingWrapper = useRef(null);

  const activeCall = phoneStore.AnyPhoneConnectionActive;
  const largeColumns = 4;
  const smallColumns = !activeCall ? 4 : 6;

  useEffect(() => {
    if (!isOnline) {
      phoneStore.incomingPhoneCalls?.[0]?.incomingTone?.pause();

      phoneStore.ActivePhoneCall?.isCallConnecting &&
        phoneStore.ActivePhoneCall.ringtone.pause();

      phoneStore.ActivePhoneCall?.isCallConnected &&
        phoneStore.noInternetOnActiveCall();
    }

    if (!phoneStore.isWebRTCConnected) {
      phoneStore.incomingPhoneCalls?.[0]?.incomingTone?.pause();

      if (phoneStore.ActivePhoneCall?.isCallConnecting) {
        phoneStore.ActivePhoneCall.ringtone.pause();
        phoneStore.ActivePhoneCall.incomingTone.pause();
      }

      phoneStore.ActivePhoneCall?.isCallConnected &&
        !phoneStore.isTenSeconds &&
        phoneStore.noInternetOnActiveCall();
    }
  }, [
    isOnline,
    phoneStore,
    phoneStore.isWebRTCConnected,
    phoneStore.incomingPhoneCalls,
    phoneStore.ActivePhoneCall,
    phoneStore.isTenSeconds,
  ]);

  const isActiveCall = () => {
    return (
      phoneStore.ActivePhoneCall &&
      !phoneStore.ActivePhoneCall.transferMode &&
      phoneStore.AnyPhoneConnectionActive &&
      isOnline &&
      phoneStore.isWebRTCConnected
    );
  };

  const isDisconnected = () => {
    return phoneStore.isTenSeconds && !isOnline;
  };

  const isInternetDropped = () => {
    return (
      !isOnline &&
      phoneStore.ActivePhoneCall?.isCallConnected &&
      !phoneStore.isTenSeconds
    );
  };

  const isConnectionDropped = () => {
    return (
      !phoneStore.isWebRTCConnected &&
      phoneStore.ActivePhoneCall?.isCallConnected &&
      isOnline
    );
  };

  const isInternetDisconnected = () => {
    return (
      !isOnline &&
      (phoneStore.ActivePhoneCall?.isCallConnecting ||
        phoneStore.incomingPhoneCalls?.length > 0)
    );
  };

  const isConnectionFailed = () => {
    return phoneStore.isTenSeconds && !phoneStore.isWebRTCConnected && isOnline;
  };

  const isAttemptingToReconnnect = () => {
    return (
      phoneStore.isAttemptingToReconnectSip ||
      (!phoneStore.isWebRTCConnected &&
        isOnline &&
        phoneStore.ActivePhoneCall?.isCallConnecting &&
        !phoneStore.isWebrtcAttemptDone)
    );
  };

  const isErrorConnectionFailed = () => {
    return (
      !phoneStore.isWebRTCConnected &&
      phoneStore.isWebrtcAttemptDone &&
      isOnline
    );
  };

  const isTransferDirectory = () => {
    return (
      phoneStore.ActivePhoneCall &&
      phoneStore.AnyPhoneConnectionActive &&
      phoneStore.ActivePhoneCall.transferMode &&
      phoneStore.isWebRTCConnected &&
      isOnline &&
      !phoneStore.isTenSeconds
    );
  };

  const isTransferOrHoldCallActive = () => {
    return (
      phoneStore.ActivePhoneCall &&
      phoneStore.AnyPhoneConnectionActive &&
      phoneStore.isWebRTCConnected &&
      isOnline
    );
  };

  const isBlindTransfer = () => {
    return (
      phoneStore.ActivePhoneCall &&
      phoneStore.ActivePhoneCall.transferMode &&
      !phoneStore.ActivePhoneCall.isWarmTransfer &&
      phoneStore.isWebRTCConnected &&
      isOnline &&
      !phoneStore.isTenSeconds
    );
  };

  const isWarmTransfer = () => {
    return (
      phoneStore.ActivePhoneCall &&
      phoneStore.ActivePhoneCall.transferMode &&
      phoneStore.ActivePhoneCall.isWarmTransfer &&
      phoneStore.isWebRTCConnected &&
      isOnline &&
      !phoneStore.isTenSeconds
    );
  };

  const isCallInHold = () => {
    return (
      phoneStore.ActivePhoneCall &&
      !phoneStore.ActivePhoneCall.transferMode &&
      phoneStore.phoneCalls.length > 1 &&
      phoneStore.isWebRTCConnected &&
      isOnline
    );
  };

  const isIncomingCall = () => {
    return (
      phoneStore.incomingPhoneCalls.length > 0 &&
      phoneStore.isWebRTCConnected &&
      isOnline
    );
  };

  const isIncomingVideoCall = conversation.listOfIncomingVideoCalls.size > 0;

  const showCallSidebar =
    phoneStore.AnyPhoneConnectionActive ||
    phoneStore.isTenSeconds ||
    isIncomingVideoCall ||
    phoneStore.isAttemptingToReconnectSip ||
    phoneStore.isWebrtcAttemptDone;

  return (
    <Styled.StyledGridColumn
      $zIndex={ui.openedRightSidebarsOrder.get('dial-pad')}
      icon="labeled"
      widescreen={largeColumns}
      computer={largeColumns}
      desktop={largeColumns}
      tablet={smallColumns}
      mobile={smallColumns}
      className={cx('nogrow call-sidebar', {
        visible: showCallSidebar,
        hidden: !showCallSidebar,
        'softphone--fixed softphone--fixed': isFixed,
      })}
    >
      {
        // Active Call
        isActiveCall() && (
          <div
            className={cx('call-active-wrapper', {
              'calling-dialpad-opened': phoneStore.ActivePhoneCall.showDialPad,
            })}
          >
            <CallActive
              phoneStore={phoneStore}
              uiStore={ui}
              personStore={person}
              cancleCallOnNoWebRTC={phoneStore.cancelCallOnNoWebRTC}
              conferenceIdGuid={phoneStore.ConferenceIdGuid}
              conferenceRoomsIdAndNumbers={
                phoneStore.conferenceRoomsIdAndNumbers
              }
              isOnline={isOnline}
              isWebrtcAttemptDone={phoneStore.isWebrtcAttemptDone}
              isWebRTCConnected={phoneStore.isWebRTCConnected}
              loadOrCreateConversationWithPost={
                conversation.loadOrCreateConversationWithPost
              }
              loggedInAccountId={person.loggedInAccountId}
              loggedInUserActiveConferenceConversation={
                conversation.LoggedInUserActiveConferenceConversation
              }
              noInternetOnActiveCall={phoneStore.noInternetOnActiveCall}
              oldSession={phoneStore.warmTransferOld}
              phoneCall={phoneStore.ActivePhoneCall}
              postConferenceByConversationId={
                conversation.postConferenceByConversationId
              }
              selectPeopleOrContactsFromConferenceRoom={
                phoneStore.selectPeopleOrContactsFromConferenceRoom as any
              }
              transferToConference={phoneStore.transferToConference}
              webrtcCounter={phoneStore.webrtcCounter}
              webRTCTimer={phoneStore.webRTCTimer}
              getExtrContactByPhoneNumber={person.getExtrContactByPhoneNumber}
              selectUnreadCounts={ui.selectConversationUnreadCounts}
              setConversationAndTotalUnreadCount={
                ui.setConversationAndTotalUnreadCount
              }
              updateMyLastReadMessage={participant.updateMyLastReadMessage}
            />
          </div>
        )
      }
      {isDisconnected() && (
        <div className="call-active-wrapper">
          <div className="call-errors">
            <div className="call-errors-wrapper webrtc-disconnected-phone-error">
              <Image src={iconConnectionError} />
              <div className="webrtc-disconnected-text">Error</div>
              <div className="webrtc-disconnected-text-cannot-connect">
                Internet Disconnected
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                {' '}
                Please reconnect to the internet and try again
              </div>
            </div>
            <Button
              className="call-error-cancel-btn"
              onClick={phoneStore.cancelBeforeTenSeconds}
            >
              <Button.Content visible>
                <span>Close</span>
              </Button.Content>
            </Button>
          </div>
        </div>
      )}

      {isInternetDropped() && (
        <div className="call-active-wrapper">
          <div className="call-errors">
            <div className="call-errors-wrapper webrtc-disconnected-phone-error">
              <Image src={iconWarning} />
              <div className="webrtc-disconnected-oops-text">Oops!</div>
              <div className="webrtc-disconnected-oops-unable-to-connect-text">
                Internet Dropped
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                Trying to reconnect
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                <Image src={spinner} />
              </div>
            </div>
            <Button
              className="call-error-cancel-btn"
              onClick={phoneStore.cancelBeforeTenSeconds}
            >
              <Button.Content visible>
                <span>Cancel</span>
              </Button.Content>
            </Button>
          </div>
        </div>
      )}
      {isConnectionDropped() && (
        <div className="call-active-wrapper">
          <div className="call-errors">
            <div className="call-errors-wrapper webrtc-disconnected-phone-error">
              <Image src={iconWarning} />
              <div className="webrtc-disconnected-oops-text">Oops!</div>
              <div className="webrtc-disconnected-oops-unable-to-connect-text">
                Connection Dropped
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                Trying to reconnect
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                <Image src={spinner} />
              </div>
            </div>
            <Button
              className="call-error-cancel-btn"
              onClick={phoneStore.cancelBeforeTenSeconds}
            >
              <Button.Content visible>
                <span>Cancel</span>
              </Button.Content>
            </Button>
          </div>
        </div>
      )}
      {isInternetDisconnected() && (
        <div className="call-active-wrapper">
          <div className="call-errors">
            <div className="call-errors-wrapper webrtc-disconnected-phone-error">
              <Image src={iconConnectionError} />
              <div className="webrtc-disconnected-text-cannot-connect">
                Internet Disconnected
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                Please reconnect to the internet and try again
              </div>
            </div>
            <Button className="call-error-cancel-btn">
              <Button.Content
                visible
                onClick={phoneStore.cancelBeforeTenSeconds}
              >
                <span>Close</span>
              </Button.Content>
            </Button>
          </div>
        </div>
      )}
      {isConnectionFailed() && (
        <div className="call-active-wrapper">
          <div className="call-errors">
            <div className="call-errors-wrapper webrtc-disconnected-phone-error">
              <Image src={iconConnectionError} />
              <div className="webrtc-disconnected-text">Error</div>
              <div className="webrtc-disconnected-text-cannot-connect">
                Connection Failed
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                {' '}
                We are unable to connect your call at this time, please try
                again later
              </div>
            </div>
            <Button className="call-error-cancel-btn">
              <Button.Content
                visible
                onClick={phoneStore.cancelBeforeTenSeconds}
              >
                <span>Close</span>
              </Button.Content>
            </Button>
          </div>
        </div>
      )}
      {isAttemptingToReconnnect() && (
        <div className="call-active-wrapper">
          <div className="call-errors">
            <div className="call-errors-wrapper webrtc-disconnected-phone-error">
              <Image src={iconWarning} />
              <div className="webrtc-disconnected-oops-text">Oops!</div>
              <div className="webrtc-disconnected-oops-unable-to-connect-text">
                Unable to connect your call
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                Attempting to reconnect in
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                {phoneStore.webRTCTimer}
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                <Image src={spinner} />
              </div>
            </div>
            <div className="attempt-counter">
              Attempt {phoneStore.webrtcCounter} out of 6
            </div>
            <Button
              className="call-error-cancel-btn"
              onClick={phoneStore.cancelCallOnNoWebRTC}
            >
              <Button.Content visible>
                <span>Cancel</span>
              </Button.Content>
            </Button>
          </div>
        </div>
      )}
      {isErrorConnectionFailed() && (
        <div className="call-active-wrapper">
          <div className="call-errors">
            <div className="call-errors-wrapper webrtc-disconnected-phone-error">
              <Image src={iconConnectionError} />
              <div className="webrtc-disconnected-text">Error</div>
              <div className="webrtc-disconnected-text-cannot-connect">
                Connection Failed
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                We cannot connect your
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                call at this time. Please try
              </div>
              <div className="webrtc-disconnected-text-attempting-to-reconnnect">
                calling again later
              </div>
            </div>
            <Button
              className="call-error-cancel-btn"
              onClick={phoneStore.cancelCallOnNoWebRTC}
            >
              <Button.Content visible>
                <span>Close</span>
              </Button.Content>
            </Button>
          </div>
        </div>
      )}
      {
        // Transfer Directory
        isTransferDirectory() && (
          <div className="transfer-component">
            <TransferDirectory />
          </div>
        )
      }
      {isTransferOrHoldCallActive() && (
        <div
          ref={incomingHoldingWrapper}
          className={cx('incoming-holding-wrapper', {
            'active-half':
              phoneStore.ActivePhoneCall &&
              phoneStore.incomingPhoneCalls.length,
            'transfer-open': phoneStore.TransferMode,
            'incoming-call': phoneStore.incomingPhoneCalls.length,
            'single-call-on-hold': phoneStore.phoneCalls.length === 2,
            'multiple-calls-on-hold': phoneStore.phoneCalls.length >= 3,
          })}
        >
          {
            // Transfer Holding List
            isBlindTransfer() && (
              <TransferHoldingList
                callHoldlist={[phoneStore.callBeingTransfered]}
              />
            )
          }
          {
            // Transfer Holding List
            isWarmTransfer() && (
              <TransferHoldingList
                callHoldlist={[phoneStore.warmTransferOld]}
              />
            )
          }
          {
            // Call Holding List
            isCallInHold() && (
              <CallHoldingList
                phoneStore={phoneStore}
                phoneCallsList={phoneStore.phoneCalls}
              />
            )
          }
        </div>
      )}
      {
        // Incoming Calls
        isIncomingCall() && (
          <CallIncomingList
            person={person}
            ui={ui}
            incomingCalls={phoneStore.incomingPhoneCalls}
            activeCallExists={!isEmpty(phoneStore.ActivePhoneCall)}
          />
        )
      }

      {isIncomingVideoCall && <IncomingVideoCall />}
    </Styled.StyledGridColumn>
  );
};

export default inject(
  STORE_PHONE_CALL,
  STORE_UI,
  STORE_CONVERSATION,
  STORE_PERSON,
  STORE_PARTICIPANT,
  STORE_PUSHER
)(observer(CallSidebar));
