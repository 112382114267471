import type { BannerProps, BannerType } from './index.types';
import { Icon, IconDefinition } from 'Components/shared/Icon';
import React from 'react';
import { BannerActions } from './BannerActions';
import { BannerIcon } from './BannerIcon';
import { Styled } from './index.styles';

const icons: Record<BannerType, IconDefinition> = {
  success: 'success-filled',
  error: 'error-filled',
  warning: 'warning-filled',
  info: 'info-filled',
};


/**
 * Banner Component
 * This component can be render as a persisted banner or within the notification stack system
 * @param BannerProps
 * @returns React.ReactElement
 */
export const Banner = ({
  type,
  id,
  title = null,
  message = null,
  action,
  onClose,
  isFullWidth = false,
  showCloseButton = true,
  isStacked = false,
  variant = 'default',
  truncate = false,
}: BannerProps): React.ReactElement => {
  const icon = icons[type];
  const computedActions = Array.isArray(action) ? action : [action];

  const showContent = message && !isStacked;
  const showFooterActions = variant === 'default' && action && !isStacked;
  const showInlineActions = variant === 'inline' && action;

  return (
    <Styled.Container
      id={id}
      $type={type}
      $isStacked={isStacked}
      $isFullWidth={isFullWidth}
    >
      <Styled.Wrapper>
        {/**
         * Content
         */}
        <Styled.Content $variant={variant}>
          {icon && <BannerIcon type={type} variant={variant} icon={icon} />}
          <Styled.TextContainer>
            {title && variant === 'default' && (
              <Styled.Title>{title}</Styled.Title>
            )}
            {showContent && (
              <Styled.MessageContainer
                $isOnlyMessage={!title}
                $variant={variant}
                $truncate={truncate}
              >
                <Styled.Message $truncate={truncate}>
                  {message}
                </Styled.Message>

                {/**
                 * Inline Actions
                 */}
                {showInlineActions && (
                  <BannerActions actions={computedActions} variant={variant} />
                )}
              </Styled.MessageContainer>
            )}
          </Styled.TextContainer>
        </Styled.Content>

        {/**
         * Close Button
         */}
        {showCloseButton && variant === 'default' && (
          <Styled.CloseButton onClick={onClose}>
            <Icon icon="cancel" />
          </Styled.CloseButton>
        )}
      </Styled.Wrapper>

      {/**
       * Footer Actions
       */}
      {showFooterActions && (
        <Styled.FooterActions>
          <BannerActions actions={computedActions} variant={variant} />
        </Styled.FooterActions>
      )}
    </Styled.Container>
  );
};
