import { FAX_URL } from 'Constants/env';
import { AuthenticatedIframeContainer } from 'Containers/IframeContainer';
import React from 'react';
import { RouteObject } from 'react-router';

const faxRoutes: RouteObject = {
  path: 'fax',
  element: <AuthenticatedIframeContainer src={FAX_URL} />,
  handle: { title: 'Fax' },
};

export default faxRoutes;
