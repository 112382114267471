import { Icon } from 'Components/shared/Icon';
import React from 'react';
import { Styled } from '../index.styles';
import { BannerIconProps } from './index.types';

/**
 * Banner Icon Element
 * @param BannerIconProps
 * @returns React.ReactElement
 */
export const BannerIcon = ({
  type,
  variant,
  icon,
}: BannerIconProps): React.ReactElement => (
  <Styled.IconWrapper $type={type} $variant={variant}>
    <Icon icon={icon} />
  </Styled.IconWrapper>
);