import { ROUTE_PATHS } from '../../constants/routing';
import { SidebarMenuProps } from './interfaces';

export const SMALL_SIDEBAR_MENU_TESTID = 'smallSideBarMenu';

export const HEADER_HEIGHT = 70;
export const SMALL_SIDEBAR_MENU_ELEMENT_HEIGHT = 60;

export const SMALL_SIDEBAR_MENU_OPTIONS: SidebarMenuProps[] = [
  {
    label: 'Message',
    icon: 'chat',
    slug: 'chat',
    to: ROUTE_PATHS.latestChat.path,
    testid: SMALL_SIDEBAR_MENU_TESTID,
  },
  {
    label: 'Call',
    icon: 'call',
    slug: 'calls',
    to: ROUTE_PATHS.calls.path,
    testid: SMALL_SIDEBAR_MENU_TESTID,
  },
  {
    label: 'Video',
    icon: 'video',
    slug: 'video-app',
    to: ROUTE_PATHS.video.path,
    testid: SMALL_SIDEBAR_MENU_TESTID,
  },
  {
    label: 'Voicemail',
    icon: 'voicemail',
    slug: 'voicemail',
    to: ROUTE_PATHS.voicemail.path,
    testid: SMALL_SIDEBAR_MENU_TESTID,
  },
  {
    label: 'Fax',
    icon: 'fax',
    slug: 'fax',
    to: ROUTE_PATHS.fax.path,
    testid: SMALL_SIDEBAR_MENU_TESTID,
  },
  {
    label: 'Directory',
    icon: 'contacts',
    slug: 'directory',
    to: ROUTE_PATHS.directory.path,
    testid: SMALL_SIDEBAR_MENU_TESTID,
  },
  {
    label: 'Contacts',
    icon: 'directory',
    slug: 'addressBook',
    to: ROUTE_PATHS.addressBook.path,
    testid: SMALL_SIDEBAR_MENU_TESTID,
  },
];
