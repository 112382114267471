import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NOTIFICATION_CONFIG } from '../config';
import { Notification } from '../NotificationItem';
import { NotificationProps } from '../NotificationItem/index.types';
import { useNotificationStore } from '../store';
import { Animation, Styled } from './index.styles';
import { NotificationStackProps } from './index.types';
import {
  getAnimationVariants,
  calculateNotificationOffsetY,
  calculateTotalHeight,
  calculateContainerWidth,
  createHoverHandler,
  updateNotificationHeights,
} from './utils';

/**
 * NotificationStack Component
 *
 * A component that manages and displays a stack of notifications with animations and interactions.
 *
 * Features:
 * - Animated entry, exit, and position transitions
 * - Dynamic height management based on notification content
 * - Hover interactions that expand the stack for better visibility
 * - Responsive width adjustments
 * - Configurable notification limit and spacing
*
 * @param {Object} props - Component props
 * @returns {React.ReactElement | null} The notification stack component or null if empty
 */
export const NotificationStack = ({
  position,
  limit = NOTIFICATION_CONFIG.DEFAULT_LIMIT,
  gap = NOTIFICATION_CONFIG.DEFAULT_GAP,
  className,
  containerId,
}: NotificationStackProps): React.ReactElement | null => {
  // Store hooks
  const notifications = useNotificationStore(
    (state) => state.notifications.get(position) || []
  );
  const updateNotification = useNotificationStore(
    (state) => state.updateNotification
  );

  // State
  const [isHovered, setIsHovered] = useState(false);
  const [containerWidth, setContainerWidth] = useState(
    NOTIFICATION_CONFIG.NOTIFICATION_WIDTH
  );
  const [heights, setHeights] = useState<Record<string, number>>({});

  // Refs
  const heightRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const containerRef = useRef<HTMLDivElement>(null);

  // Computed values
  const visibleNotifications = notifications.slice(0, limit);
  const componentTestId = `notification-stack-${position}`;

  /**
   * Reset hover state when notifications are cleared
   */
  useEffect(() => {
    if (notifications.length === 0) {
      setIsHovered(false);
    }
  }, [notifications.length]);

  /**
   * Update notification heights on content change
   */
  useEffect(() => {
    const handleHeightUpdate = () => {
      const newHeights = updateNotificationHeights(heightRefs.current);
      const hasHeightChanged = Object.entries(newHeights).some(
        ([id, height]) => heights[id] !== height
      );

      if (hasHeightChanged) {
        // Infinite loops lead to the dark side. Compare heights we must, before update we do
        setHeights(newHeights);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(handleHeightUpdate);
    });

    Object.values(heightRefs.current).forEach((ref) => {
      if (ref) {
        resizeObserver.observe(ref);
      }
    });

    handleHeightUpdate();

    return () => {
      resizeObserver.disconnect();
    };
  }, [notifications.length, isHovered, heights]);

  /**
   * Update container width on window resize
   */
  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(calculateContainerWidth());
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * Handlers
   */
  const handleHover = createHoverHandler(
    setIsHovered,
    NOTIFICATION_CONFIG.HOVER_TIMEOUT
  );

  const handleAnimationComplete = useCallback(
    (definition: string, notification: NotificationProps, index: number) => {
      if (
        definition === 'animate' &&
        index === 0 &&
        !notification.hasAnimated
      ) {
        updateNotification(position, notification.id.toString(), {
          ...notification,
          hasAnimated: true,
        });
      }
    },
    [position, updateNotification]
  );

  /**
   * Helper functions
   */
  const getNotificationOffsetY = (index: number) =>
    calculateNotificationOffsetY(
      index,
      isHovered,
      visibleNotifications,
      heights,
      gap
    );

  const getTotalHeight = () =>
    calculateTotalHeight(isHovered, visibleNotifications, heights, gap);

  if (visibleNotifications.length === 0) {
    return null;
  }

  return (
    <Styled.Container
      ref={containerRef}
      id={containerId}
      className={className}
      $position={position}
      $width={containerWidth}
      data-testid={`${componentTestId}-container`}
    >
      <Styled.NotificationWrapper
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        $height={getTotalHeight()}
        $gap={gap}
        data-testid={`${componentTestId}-wrapper`}
      >
        <AnimatePresence mode="sync">
          {visibleNotifications.map((notification, index) => {
            const variants = getAnimationVariants(
              index,
              position,
              isHovered,
              getNotificationOffsetY,
              notification
            );

            return (
              <Styled.NotificationItem
                key={notification?.id}
                layout="position"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={variants}
                transition={{
                  y: Animation.stackTransition,
                  scale: Animation.springTransition,
                  opacity: Animation.opacityTransition,
                  layout: Animation.springTransition,
                }}
                onAnimationComplete={(definition: string) =>
                  handleAnimationComplete(definition, notification, index)
                }
                $position={position}
                $index={index}
              >
                <Styled.NotificationContent
                  ref={(el) => (heightRefs.current[notification.id] = el)}
                  $isHovered={isHovered}
                  $index={index}
                  data-testid={`notification-content-${notification.id}`}
                >
                  <Notification
                    notification={notification}
                    position={position}
                    isStacked={!isHovered && index > 0}
                    isHovered={isHovered}
                  />
                </Styled.NotificationContent>
              </Styled.NotificationItem>
            );
          })}
        </AnimatePresence>
      </Styled.NotificationWrapper>
    </Styled.Container>
  );
};
