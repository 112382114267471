import { useCallback, useState } from 'react';

/**
 * Maximum size of message in bytes. Effectively, 10KB.
 */
const MAX_MESSAGE_SIZE = 10240;

/**
 * Manages `messageIsTooLong` state, which starts as `false`.
 * Returns a function `checkIsMessageTooLong` that can be used to check if given message is too long, and sets `messageIsTooLong` to true if it is.
 * Returns a function `resetMessageIsTooLong` that can be used to reset the `messageIsTooLong` state to false.
 * @returns anobject containing `messageIsTooLong` state, `checkIsMessageTooLong` and `resetMessageIsTooLong` functions.
 */
const useCheckLongMessage = () => {
  const [messageIsTooLong, setMessageIsTooLong] = useState(false);

  const checkIsMessageTooLong = useCallback((message: string) => {
    if (new TextEncoder().encode(message).byteLength > MAX_MESSAGE_SIZE) {
      setMessageIsTooLong(true);
      return true;
    }

    return false;
  }, []);

  const resetMessageIsTooLong = useCallback(() => {
    setMessageIsTooLong(false);
  }, []);

  return { messageIsTooLong, checkIsMessageTooLong, resetMessageIsTooLong };
};

export default useCheckLongMessage;
