import { BannerWrapper } from 'Components/BannerWrapper';
import React from 'react';
import { Styled } from './index.styles';
import { ContentAreaProps } from './types';

export const ContentArea: React.FC<ContentAreaProps> = ({
  id,
  children,
  inlineTopBanner,
}) => {
  return (
    <Styled.ContentAreaWrapper {...{ id }}>
      <BannerWrapper defaultBanner={inlineTopBanner} />
      <Styled.ContentArea $inlineTopBanner={!!inlineTopBanner}>
        <Styled.Content>{children}</Styled.Content>
      </Styled.ContentArea>
    </Styled.ContentAreaWrapper>
  );
};
