import { TextLink } from 'Components/shared/TextLink';
import { IS_ELECTRON } from 'Constants/env';
import React from 'react';
import SimpleMarkdown, { ParserRule, ReactOutputRule } from 'simple-markdown';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { sendIpcOpenVideo } from 'Utils/ipcRendererEvents';
import { Styled } from './buildLinkText.styles';
import { electronOpenExternal } from '../electronHelpers';

/**
 * @param buildLinkPreview An `action` which should load the link preview for the Message model, given the URI.
 */
export const buildLinkText = (
  buildLinkPreview: (nodeTarget: string) => void
): ParserRule & ReactOutputRule => ({
  ...SimpleMarkdown.defaultRules.link,
  order: 0,
  match: (matchSrc) =>
    /^(\[[A-z0-9 _]*]\()?((?:(http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6})(\))?$/.exec(
      matchSrc
    ),
  parse: (capture) => ({
    content: capture[2],
    target: capture[2],
    title: capture[3],
  }),
  react: (node, _output, state) => {
    buildLinkPreview(node.target);
    let linkTarget: string = node.target;

    if (
      !linkTarget?.startsWith('http://') &&
      !linkTarget?.startsWith('https://')
    ) {
      linkTarget = 'https://' + linkTarget;
    }

    return (
      <Styled.LinkWrapper>
        <TextLink
          key={state.key}
          testid={`markdownCompiler-linkText-${state.key}`}
          underline
          {...(IS_ELECTRON
            ? {
                'data-ext-url': linkTarget,
                onClick: (e) => {
                  // Open native OS browser in Electron *unless* it's a video link
                  linkTarget.indexOf(window.location.origin + '/video') === -1
                    ? electronOpenExternal(e, linkTarget)
                    : !sendIpcOpenVideo(linkTarget) &&
                      console.error(
                        'markdownCompiler.buildLinkAddTargetBlankRule: Electron env ipcRenderer not found'
                      );
                },
              }
            : {
                href: linkTarget,
                onClick: () => {
                  window.open(linkTarget);
                  pushToGTMDataLayer('linkClick');
                },
              })}
        >
          {linkTarget}
        </TextLink>
      </Styled.LinkWrapper>
    );
  },
});
