/**
 * Helper function to check if a date is today
 * @param {string | null} dateString - ISO date string to check
 * @returns {boolean} True if the date is today
 */
export const isToday = (dateString: string | null): boolean => {
  if (!dateString) return false;
  const date = new Date(dateString);
  const today = new Date();
  const result = date.toDateString() === today.toDateString();

  return result;
};

/**
 * Type guard to validate person ID and clean invalid cache entries
 * @param {string | null | undefined} personId - The person ID to validate
 * @param {Map<string, any>} storeCache - The store cache to clean
 * @returns {boolean} True if the person ID is valid
 */
export const isValidPersonId = (
  personId: string | null | undefined,
  storeCache: Map<string, any>
): personId is string => {
  if (!personId) return false;
  if (personId === '-1') return false;
  if (personId.trim() === '') return false;

  // Clean invalid store from cache if it exists
  if (storeCache.has('-1')) {
    storeCache.delete('-1');
  }

  return true;
};