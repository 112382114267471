// Note that these values should NOT be changed. If they are changed, all properties in interfaces (such as `WithRouterWrapperProps` and any container props) must be updated to reflect these values as store names.

export const STORE_CONVERSATION = 'conversation';
export const STORE_MESSAGE = 'message';
export const STORE_NOTIFICATION = 'notification';
export const STORE_PERSON = 'person';
export const STORE_CALL_LOGS = 'callLogsStore';
export const STORE_PUSHER = 'pusher';
export const STORE_ROUTER = 'router';
export const STORE_PARTICIPANT = 'participant';
export const STORE_PHONE_CALL = 'phoneCall';
export const STORE_SEARCH = 'search';
export const STORE_CONTACT = 'contact';
export const STORE_UI = 'ui';
export const STORE_ROOT = 'root';
export const STORE_PREFERENCE = 'preference';
export const STORE_CONFIG = 'config';
