import type { MicrosoftBusinessProps } from './types';
import logo from 'Assets/images/outlook-icon.svg';
import { AZURE_APP_CLIENT_ID, IS_ELECTRON, REDIRECT_URL } from 'Constants/env';
import { has } from 'lodash';
import React, { useEffect, useState } from 'react';
import { handleCodeReceived, sendIpcLoginUrl } from 'Utils/ipcRendererEvents';
import ProviderButton from '../ProviderButton';

const MicrosoftBusiness = ({
  person,
  testId,
}: MicrosoftBusinessProps): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const randomString = (length: number) => {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };

  const scopes = [
    'offline_access',
    'openid',
    'email',
    'profile',
    'User.Read',
    'https://outlook.office365.com/EAS.AccessAsUser.All',
    'https://outlook.office365.com/EWS.AccessAsUser.All',
  ];

  const scope = encodeURIComponent(scopes.filter((a) => a).join(' '));

  const microsoftRedirectionLink = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
  client_id=${AZURE_APP_CLIENT_ID}&
  response_type=code&
  redirect_uri=${encodeURIComponent(`${REDIRECT_URL}/addressBook/sources`)}&
  response_mode=query&
  prompt=select_account&
  scope=${scope}&
  state=contacts&
  nonce=${randomString(6)}`;

  const handleWebRedirection = () =>
    (window.location.href = microsoftRedirectionLink);

  const logIn = () => {
    if (IS_ELECTRON && has(window, 'ipcRenderer')) {
      handleCodeReceived((code) => {
        if (loading) {
          return;
        }

        setLoading(true);
        person
          .sendCodeOfProvider(code, 'office365')
          .then((res) => {
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      });
      sendIpcLoginUrl(microsoftRedirectionLink);
    } else {
      handleWebRedirection();
    }
  };
  useEffect(() => {
    const { search } = window?.location;
    if (!IS_ELECTRON) {
      if (search.includes('code=')) {
        setLoading(true);
        const code = search.split('&').find((item) => !!item.includes('code'));

        person
          .sendCodeOfProvider(code.split('code=')[1], 'office365')
          .then((res) => {
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  }, []);

  return (
    <ProviderButton
      text="Sign in with Outlook Business"
      logo={logo}
      isLoading={loading}
      onClick={logIn}
      testId={`${testId}-microsoftBusiness-buttonSignIn`}
    />
  );
};

export default MicrosoftBusiness;
