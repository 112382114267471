
import MyRoom from 'Components/VideoComponent/VideoMenu/MyRoom';
import VideoHistory from 'Components/VideoComponent/VideoMenu/VideoHistory';
import VideoHistoryDetails from 'Components/VideoComponent/VideoMenu/VideoHistory/VideoHistoryDetails';
import NewRoom from 'Components/VideoPanel/NewRoom';
import { VIDEO_SUBPATHS } from 'Constants/routing';
import VideoContainer from 'Containers/VideoContainer';
import React from 'react';
import { Navigate, RouteObject } from 'react-router';

const videoAppRoutes: RouteObject = {
  path: 'video-app/*',
  element: <VideoContainer />,
  handle: { title: 'Video App' },
  children: [
    { path: 'room', element: <MyRoom /> },
    { path: 'newroom', element: <NewRoom /> },
    { path: 'history', element: <VideoHistory />,
      handle: { title: 'History' }
     },
    {
      path: 'history/:videoHistoryId',
      element: <VideoHistoryDetails />,
      handle: { title: ':videoHistoryId' },
    },
    {
      path: '*',
      element: <Navigate to={VIDEO_SUBPATHS.PERSONAL_ROOM} replace />,
    },
  ],
};

export default videoAppRoutes;
