import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { POSITIONS } from '../config';
import { NotificationStack } from '../NotificationStack';

interface NotificationProviderProps {
  children?: React.ReactNode;
}

export const NotificationProvider = ({
  children,
}: NotificationProviderProps): React.ReactElement => {
  const [mounted, setMounted] = useState(false);
  const portalId = 'notification-stacks-portal';

  useEffect(() => {
    setMounted(true);

    let portalContainer = document.getElementById(portalId);

    if (!portalContainer) {
      portalContainer = document.createElement('div');
      portalContainer.id = portalId;
      document.body.appendChild(portalContainer);
    }

    return () => {
      setMounted(false);
      const existingPortal = document.getElementById(portalId);
      if (existingPortal) {
        existingPortal.remove();
      }
    };
  }, []);

  if (!mounted) return <>{children}</>;

  const portalContainer = document.getElementById(portalId);
  if (!portalContainer) return <>{children}</>;

  return (
    <>
      {children}
      {createPortal(
        <>
          {POSITIONS.map((position) => (
            <NotificationStack
              key={position}
              position={position}
              containerId={`notification-stack-${position}`}
            />
          ))}
        </>,
        portalContainer
      )}
    </>
  );
};
