import type { TopBarDropdownContextProps } from './TopBarDropdownContext';
import type { TopBarDropdownContextWrapperProps } from './TopBarDropdownContextWrapper.types';
import { useFavoriteOptions } from 'Components/TopBar/hooks/useFavoriteOptions';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { TopBarDropdownContext } from './TopBarDropdownContext';

export const TopBarDropdownContextWrapper: React.FC<
  TopBarDropdownContextWrapperProps
> = ({ children }) => {
  const {
    phoneCall: { callWithPerson },
    person: { loadPersonByIdGet },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const { favoriteList, mapFavoritesOptions } = useFavoriteOptions();

  const [options, setOptions] = React.useState<
    TopBarDropdownContextProps['options']
  >([]);

  const [selection, setSelection] = React.useState<
    TopBarDropdownContextProps['selection']
  >({ users: [], variant: null });

  const makeCall = (
    data: { personId?: string; phoneNumbers?: string[] },
    e?: any
  ) => {
    if (data.phoneNumbers?.length > 1) return;

    const users = data.personId ? [data.personId] : [data.phoneNumbers[0]];
    const numberIsInsideOptions = options.some(async ({ key }) => {
      try {
        const p = await loadPersonByIdGet(Number(key));

        return (
          users.includes(key) ||
          (!!p?.data?.lines?.length &&
            users.includes(p.data.lines[0]?.callerId))
        );
      } catch (err) {
        console.warn(
          `Failed to load Person ${key}.  This may be caused by a removed Person or unassigned number, which is not an error.`,
          err
        );
        return false;
      }
    });

    if (!numberIsInsideOptions) return;
    e?.stopPropagation();
    return data?.phoneNumbers?.length === 1
      ? callWithPerson(null, data?.phoneNumbers[0])
      : callWithPerson(Number(data.personId), null);
  };

  return (
    <TopBarDropdownContext.Provider
      value={{
        makeCall,
        options,
        setOptions,
        selection,
        setSelection,
        favoriteList,
        mapFavoritesOptions,
      }}
    >
      {children}
    </TopBarDropdownContext.Provider>
  );
};
