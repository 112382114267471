import { has, isEmpty } from 'lodash';
import { IS_ELECTRON } from '../constants/env';
import { handleElectronOpenExternal } from './ipcRendererEvents';

/**
 * Opens a link with the `data-ext-url` attribute in the OS Native browser, if in Electron.
 *
 * Otherwise, return `true` to defer to default event handler.
 *
 * @export
 * @param e A click event on an `<a>` element
 * @returns
 */
export function electronOpenExternal(
  e: React.MouseEvent<HTMLAnchorElement>,
  url?: string
) {
  if (IS_ELECTRON && has(window, 'ipcRenderer')) {
    e.preventDefault();
    const extUrl = url ?? e.currentTarget.getAttribute('data-ext-url');
    if (!isEmpty(extUrl)) {
      handleElectronOpenExternal(extUrl);
    }
    return false;
  }
  return true; // Defer to default click event handler
}
