import { action, computed, makeObservable, observable } from 'mobx';
import { Location } from 'react-router';
import { RootStore } from 'Stores/RootStore';

/**
 * @deprecated ONLY USE ROUTER STORE INSIDE OTHER STORES,
 * as the stores cannot access the `react-router` Provider in any other way.
 * For components, please use `react-router` hooks instead.
 **/
export class RouterStore {
  rootStore: RootStore;

  @observable
  location: Location | null = null;

  @observable
  requestedNavigationTo: string = null;

  @observable
  requestedRedirectionTo: string = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.location = { key: '', state: {}, ...window.location };
    makeObservable(this);
  }

  @computed
  get currentPath() {
    return this.location?.pathname || window.location.pathname;
  }

  @action
  setReactRouterLocation(newState: Location) {
    this.location = newState;
  }

  @action
  push(path: string) {
    this.requestedNavigationTo = path;
  }

  @action
  replace(path: string) {
    this.requestedRedirectionTo = path;
  }

  @action
  reset() {
    this.requestedNavigationTo = null;
    this.requestedRedirectionTo = null;
  }
}
