import { SecondaryMenu } from 'Components/shared/SecondaryMenu';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from 'Constants/responsiveness';
import { CALLS_SUBMENU_OPTIONS } from 'Constants/routing';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import { Outlet } from 'react-router';

import { Grid } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';

export const CallLogs: React.FC = () => {
  const {
    ui: {
      isTouchSupported,
      openTopbarDialpad,
      removeFromOpenedRightSidebarsOrder,
      setDeletePopupDisplay,
      setHideSecondaryMenu,
      setOpenedRightSidebarsOrder,
      setOpenTopbarDialpad,
      hideSecondaryMenu,
    },
    phoneCall: { ActivePhoneCall, incomingPhoneCalls },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  React.useEffect(() => {
    if (isTouchSupported() && window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT)
      setHideSecondaryMenu(false);

    const handleOpenDialpad = () => {
      if (ActivePhoneCall || incomingPhoneCalls.length) return;
      setOpenTopbarDialpad(true);
      setOpenedRightSidebarsOrder('dial-pad');
    };

    const handleCloseDialpad = () => {
      if (openTopbarDialpad) return;
      removeFromOpenedRightSidebarsOrder('dial-pad');
      setOpenTopbarDialpad(false);
    };

    window.innerWidth > 1024 && handleOpenDialpad();

    return () => {
      handleCloseDialpad();
    };
  }, []);

  return (
    <Grid.Column
      className="calls-container flex-row"
      onClick={() => setDeletePopupDisplay(false)}
    >
      {!hideSecondaryMenu && (
        <SecondaryMenu text="Recent calls" options={CALLS_SUBMENU_OPTIONS} />
      )}
      <Outlet />
    </Grid.Column>
  );
};
