import type { DialpadIconButtonProps } from './types';
import { STORE_PHONE_CALL, STORE_UI } from 'Constants/stores';
import { TESTID_BUTTONS, TESTID_TOP_BAR } from 'Constants/testids';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Styled } from './index.styles';

export const DialpadIconButtonObserver: React.FC<DialpadIconButtonProps> = ({
  phoneCall,
  ui,
}) => {
  const handleOpenDialpad = () => {
    ui.setOpenedRightSidebarsOrder('dial-pad');

    ui.setOpenTopbarDialpad(!ui.openTopbarDialpad);
  };

  return (
    <Styled.IconButton
      content="Dial pad"
      compact
      icon="dialpad"
      onClick={handleOpenDialpad}
      popupPosition="bottom center"
      size="medium"
      testid={TESTID_TOP_BAR['BAR'] + TESTID_BUTTONS['DIAL_PAD']}
      variant="secondary"
      active={ui.openTopbarDialpad}
      disabled={{
        title: "Can't access the dial pad during a call",
        condition:
          !!phoneCall.ActivePhoneCall || !!phoneCall.incomingPhoneCalls?.length,
      }}
    />
  );
};

export const DialpadIconButton = inject(
  STORE_PHONE_CALL,
  STORE_UI
)(observer(DialpadIconButtonObserver));
