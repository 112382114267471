import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  CALL_LOGS_PER_PAGE,
  CALL_TYPES_CONTENT,
} from 'Components/CallLogs/constants';
import { CallLogCategoryType } from 'Components/CallLogs/types';
import { mapImageToCallLogs } from 'Components/CallLogs/utils';
import { API_ENDPOINTS } from 'Constants/env';
import { DateTime } from 'luxon';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import API from '~/api';
import {
  IFetchCallLogs,
  IFetchCallLogsMissedCount,
  CallLogsQueryParams,
  typesOfCallsId,
  CallLogsMissedCountQueryParams,
} from './index.types';

export const QUERY_KEY_FETCH_CALL_LOGS_MISSED_COUNT =
  'callLogs.fetchMissedCount';
export const QUERY_KEY_FETCH_CALL_LOGS = 'callLogs.fetchCallLogs';

const fetchCallLogs = async ({
  pageParam = '',
  callId,
  query,
}: IFetchCallLogs) => {
  const response = await API.get(
    API_ENDPOINTS.CallLogsSearch(
      CALL_LOGS_PER_PAGE,
      CALL_TYPES_CONTENT[callId].direction,
      CALL_TYPES_CONTENT[callId].disposition,
      query,
      pageParam
    )
  );

  return mapImageToCallLogs(response.data.data);
};

const fetchCallLogsCount = async ({
  since,
}: IFetchCallLogsMissedCount): Promise<number> => {
  const res = await API.get(API_ENDPOINTS.CallLogsCount(since));

  return res?.data?.data || 0;
};

export const useCallLogs = ({
  typeOfCallId,
  query = '',
  enabled = true,
  refetchInterval,
}: CallLogsQueryParams) => {
  const {
    callLogsStore: { setLastSyncDate },
    router: { location },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const callId =
    typeOfCallId || (location.pathname.split('/').pop() as CallLogCategoryType);

  const isCorrectLocation = typesOfCallsId.includes(callId);

  const isEnabled = isCorrectLocation && enabled;

  return useInfiniteQuery({
    queryKey: [QUERY_KEY_FETCH_CALL_LOGS, callId, query],
    queryFn: async ({ pageParam }) => {
      const res = await fetchCallLogs({ pageParam, callId, query });
      setLastSyncDate(DateTime.now().toString());
      return res;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.length < CALL_LOGS_PER_PAGE) {
        return undefined;
      }
      return `${lastPage.slice(-1)[0]?.id}`;
    },
    initialPageParam: '',
    enabled: isEnabled,
    refetchInterval,
    gcTime: 0,
    staleTime: 0,
  });
};

export const useCallbackCount = ({ since }: CallLogsMissedCountQueryParams) => {
  return useQuery({
    queryKey: [QUERY_KEY_FETCH_CALL_LOGS_MISSED_COUNT, since],
    queryFn: async () => await fetchCallLogsCount({ since }),
    enabled: !!since,
  });
};
