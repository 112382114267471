import { IDefaultConfig } from './index.types';

export const STORAGE_KEY = 'application_logs';

export const DEFAULT_CONFIG: IDefaultConfig = {
  SEND_INTERVAL_MS: 5 * 60 * 1000,
  RETRY_ATTEMPTS: 3,
  RETRY_DELAY: 5000,
  MAX_LOGS_COUNT: 5000,
} as const;
