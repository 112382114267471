import AllContacts from 'Components/AllContacts';
import ContactsAddressBook from 'Components/ContactsAddressBook';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';

export const ContactsListWrapper = observer(({ ...props }) => {
  const {
    person: { hasContacts },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  return hasContacts ? (
    <AllContacts {...props} />
  ) : (
    <ContactsAddressBook {...props} />
  );
});
