import CreateChatWithEmail from 'Components/CreateChatWithEmail';
import React from 'react';
import { RouteObject } from 'react-router';

const emailRoutes: RouteObject = {
  path: 'email',
  element: <CreateChatWithEmail />,
  handle: { title: 'Email' },
};

export default emailRoutes;
