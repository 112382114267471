import type { IframeContainerProps } from './types';
import { MobXProviderContext } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Iframe from 'react-iframe-click';
import { ContentArea } from '../../components/shared/ContentArea';
import { RootStoreProps } from '../../stores/RootStore.types';
import { Styled } from './index.styles';

export const IframeContainer: React.FC<IframeContainerProps> = ({ src }) => (
  <Styled.IframeContainer>
    <ContentArea>
      <Iframe
        //@ts-ignore
        src={src}
        width="100%"
        height="99%"
        frameBorder={0}
        onInferredClick={() => {}} /* required by react-iframe-click */
      />
    </ContentArea>
  </Styled.IframeContainer>
);

export const AuthenticatedIframeContainer = ({ src }: { src: string }) => {
  const { person } = React.useContext<RootStoreProps>(MobXProviderContext);
  const [token, setToken] = useState<string | null>(null);

  // Memoize the fetch token logic
  const fetchAuthToken = useCallback(async (): Promise<void> => {
      const authToken = await person.tryLoginFromLocalForage();
      setToken(authToken || null);
  }, [person]);

  // Use `useEffect` to fetch the token when the component mounts
  useEffect(() => {
    void fetchAuthToken();
  }, [fetchAuthToken]);

  // Use `useMemo` to compute the iframe source only when the `src` or `token` changes
  const iframeSrc = useMemo(() => (token ? `${src}#${token}` : src), [src, token]);

  return <IframeContainer key={token} src={iframeSrc} />;
}