import { motion } from 'framer-motion';
import styled from 'styled-components';
import { NotificationStackPosition } from './index.types';

const NOTIFICATION_CONTAINER_Z_INDEX = 9998; // Highest z-index for stack container
const NOTIFICATION_ITEM_Z_INDEX = 50; // Relative z-index to order the notifications within the container

const getPositionStyles = (position: NotificationStackPosition) => {
  const positions = {
    tl: 'top: 80px; left: 10px;',
    tc: 'top: 16px; left: 50%; transform: translateX(-50%);',
    tr: 'top: 80px; right: 10px;',
    bl: 'bottom: 16px; left: 10px;',
    bc: 'bottom: 16px; left: 50%; transform: translateX(-50%);',
    br: 'bottom: 16px; right: 10px;',
  };

  return positions[position];
};

/**************************************************
 ************ Framer Motion Transitions ***********
 **************************************************/
/**
 * Spring transition for individual notification animations (entry/exit)
 * - type: Defines the animation type as a spring physics-based animation
 * - stiffness: How strong the spring is (higher = more rigid and faster movement)
 * - damping: How quickly the spring's motion slows down (higher = less bouncy)
 * - mass: The weight of the object being animated (lower = faster acceleration)
 * - restDelta: Smallest distance from target before spring is considered at rest
 */
const springTransition = {
  type: 'spring',
  stiffness: 500,   // Controls the spring force (higher = snappier)
  damping: 35,      // Controls the spring bounce (higher = less bounce)
  mass: 0.5,        // Controls the "weight" (lower = quicker movement)
  restDelta: 0.01   // Controls when spring stops (smaller = more precise)
};

/**
 * Spring transition for stacked notifications movement
 * - Uses softer values for smoother group animations
 * - Higher mass for more "weighted" feel when stack moves
 */
const stackTransition = {
  type: 'spring',
  stiffness: 300,   // Softer spring for smoother stack movement
  damping: 30,      // Balanced damping for natural stack motion
  mass: 0.8,        // Heavier mass for more deliberate movement
  restDelta: 0.01   // Same precision for consistent animation end
};

/**
 * Simple tween transition for opacity changes
 * - Used for fade in/out effects
 * - Simpler than spring for basic opacity animations
 */
const opacityTransition = {
  type: 'tween',    // Linear interpolation instead of spring physics
  duration: 0.2,    // Animation duration in seconds
  ease: 'easeOut'   // Starts fast and slows down at the end
};

/**************************************************
 ************ Notification Stack Styles ***********
 **************************************************/
const Container = styled.div<{
  $position: NotificationStackPosition;
  $width: number;
}>`
  position: fixed;
  z-index: ${NOTIFICATION_CONTAINER_Z_INDEX};
  ${({ $position }) => getPositionStyles($position)}
  ${({ $position, $width }) =>
    $position.includes('c')
      ? `
    width: 100%;
    padding: 0 1rem;
    max-width: ${$width}px;
  `
      : `
    width: ${$width}px;
  `}
  pointer-events: none;
`;

const NotificationWrapper = styled.div<{ $gap: number, $height: number }>`
  height: ${({ $height }) => $height}px;
  gap: ${({ $gap }) => $gap}px;
  transition: height 150ms ease-out;
  pointer-events: auto;
  width: 100%;
  padding: 1px;
  margin: -1px;
`;

const NotificationContent = styled.div<{
  $isHovered: boolean;
  $index: number;
}>`
  transition: all ${({ theme }) => theme.transitions.default};
  opacity: ${({ $isHovered, $index }) =>
    $isHovered ? 1 : $index > 0 ? 0.9 : 1};
`;

const NotificationItem = styled(motion.div)<{
  $position: NotificationStackPosition;
  $index: number;
}>`
  position: absolute;
  width: 100%;
  transform-origin: ${({ $position }) =>
    $position.startsWith('t') ? 't' : 'b'};
  pointer-events: auto;
  z-index: ${({ $index }) => NOTIFICATION_ITEM_Z_INDEX - $index};
`;

export const Styled = {
  Container,
  NotificationWrapper,
  NotificationContent,
  NotificationItem,
};

export const Animation = {
  springTransition,
  stackTransition,
  opacityTransition,
};
