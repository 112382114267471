

import { ContentArea } from 'Components/shared/ContentArea';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_UI,
} from 'Constants/stores';
import { Channel as ChannelContainer } from 'Containers/Channel';
import { ContextPanelProps } from 'Containers/ContextPanel/interfaces';
import withRouter from 'Hocs/WithRouter';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Outlet } from 'react-router';
import { Grid } from 'semantic-ui-react';

class VideoPanel extends React.Component<ContextPanelProps> {
  render() {
    const { ui } = this.props;

    return (
      <ContentArea>
        <Grid.Column className="video-panel">
          <ChannelContainer
            openGroupModal={ui.groupModalOpen}
            {...this.props}
          />
          <Outlet />
        </Grid.Column>
      </ContentArea>
    );
  }
}
export default inject(
  STORE_PERSON,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(withRouter(observer(VideoPanel)));
