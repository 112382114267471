import type { ProfileOverviewProps } from './interfaces';
import { AvatarEditor } from 'Components/AvatarEditor';
import { isEmpty } from 'lodash';
import React from 'react';
import { formatNumber } from 'Utils/phoneUtil';
import { Styled } from './index.styles';

export const ProfileOverview: React.FC<ProfileOverviewProps> = ({
  profilePictureUrl,
  loadingProfilePictureUrl,
  fileImportEvent,
  username,
  localExtension,
  email,
  inboundNumbers,
  outboundNumbers,
  testid,
}) => (
  <div className="settings-profile">
    <Styled.AvatarEditorContainer>
      <AvatarEditor
        pictureUrl={profilePictureUrl}
        isLoading={loadingProfilePictureUrl}
        fileImportEvent={fileImportEvent}
        testid={testid}
      />
    </Styled.AvatarEditorContainer>

    <div className="settings-profile--desc">
      <span className="title">Managed by admin</span>
      {username && (
        <p>
          <span className="bold-text">Username:</span> {username}
        </p>
      )}
      {localExtension && (
        <p data-private>
          <span className="bold-text">Local Extension:</span> {localExtension}
        </p>
      )}
      {email && (
        <p data-private>
          <span className="bold-text">Email:</span> {email}
        </p>
      )}
      {!isEmpty(inboundNumbers) && (
        <div className="flex-row flex-align-items-center">
          <span className="bold-text">Inbound numbers:</span>
          <div data-private className="inbound-numbers">
            {inboundNumbers.map((line) => (
              <span key={line.number}>{formatNumber(line.number)}</span>
            ))}
          </div>
        </div>
      )}
      {!isEmpty(outboundNumbers) && (
        <div className="flex-row flex-align-items-center">
          <span className="bold-text">Outbound numbers:</span>
          <div data-private className="outbound-numbers">
            {outboundNumbers.map((line) => {
              return <span key={line.PhoneDisplay}>{line.PhoneDisplay}</span>;
            })}
          </div>
        </div>
      )}
    </div>
  </div>
);
