import ContextContentLoadingContainer from 'Containers/ContextContentLoadingContainer';
import React from 'react';
import { RouteObject } from 'react-router';

const welcomeRoutes: RouteObject = {
  path: 'welcome',
  element: <ContextContentLoadingContainer />,
  handle: { title: 'Welcome' },
};

export default welcomeRoutes;
