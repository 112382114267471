import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  HistoryConversationsStore,
  HistoryConversationsStoreState,
} from './index.types';

const initialState: HistoryConversationsStoreState = {
  historyConversations: [],
  historyFetchParams: undefined,
};

const historyConversationsStore = create(
  devtools<HistoryConversationsStore>((set, get) => ({
    ...initialState,
    updateHistoryConversations: (historyConversations) => {
      set({ historyConversations });
    },
    updateHistoryFetchParams: (
      conversationCurrentDate,
      conversationSubDate
    ) => {
      set({
        historyFetchParams: { conversationCurrentDate, conversationSubDate },
      });
    },
  }))
);

export const useHistoryConversationsStore = historyConversationsStore;
