import type { SidebarMenuProps, SmallSidebarMenuProps } from './interfaces';

import Badge from 'Components/shared/Badge';
import { BadgeProps } from 'Components/shared/Badge/index.types';
import { Icon } from 'Components/shared/Icon';
import type { IconDefinition } from 'Components/shared/Icon/index.generated';
import { PORTAL_URL } from 'Constants/env';
import { NYLAS_V3 } from 'Constants/featureFlags';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from 'Constants/responsiveness';
import { MAIN_TABS, ROUTE_PATHS } from 'Constants/routing';
import { STORE_PERSON, STORE_UI } from 'Constants/stores';
import withRouter from 'Hocs/WithRouter';
import { useBadgeCounters } from 'Hooks/badgeCounters';
import useFeatureFlags from 'Hooks/featureFlags/useFeatureFlags';
import { inject, observer } from 'mobx-react';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { hasStaleSources } from 'Utils/contactSyncSource';
import { useWindowHeight } from '~/lib/hooks/useWindowHeight';
import {
  HEADER_HEIGHT,
  SMALL_SIDEBAR_MENU_ELEMENT_HEIGHT,
  SMALL_SIDEBAR_MENU_OPTIONS,
  SMALL_SIDEBAR_MENU_TESTID,
} from './constants';
import { Styled } from './index.styles';
import { SideBarMenuButton } from './SideBarMenuButton';
import { SidebarMenuDrawer } from './SidebarMenuDrawer';

type BadgeData = {
  option: string;
  level: BadgeProps['level'];
  count: number;
  size: BadgeProps['size'];
};

const SmallSidebarMenu: React.FC<SmallSidebarMenuProps> = ({
  person,
  ui: { setHideSecondaryMenu, isTouchSupported, hideSecondaryMenu },
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const height = useWindowHeight();

  const numberOfElementsInSidebarMenu = Math.floor(
    (height - HEADER_HEIGHT - SMALL_SIDEBAR_MENU_ELEMENT_HEIGHT) /
      SMALL_SIDEBAR_MENU_ELEMENT_HEIGHT
  );

  const { voicemailQuery } = useBadgeCounters();

  const handleActionPanelVisibility = useCallback(() => {
    if (window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT) {
      if (isTouchSupported()) {
        setHideSecondaryMenu(false);
      } else setHideSecondaryMenu(true);
    } else {
      setHideSecondaryMenu(false);
    }
  }, [isTouchSupported, setHideSecondaryMenu]);

  useEffect(() => {
    window.addEventListener('resize', handleActionPanelVisibility);
    return () => {
      window.removeEventListener('resize', handleActionPanelVisibility);
    };
  }, [handleActionPanelVisibility]);

  useEffect(() => {
    if (person?.allSources?.length === 0) {
      void person.getSourcesIfMissing();
    }
  }, [person]);

  const { [NYLAS_V3]: isNylasV3FlagEnabled } = useFeatureFlags([NYLAS_V3]);

  const addBadgeToAddressBookOption = useCallback(
    (option: SidebarMenuProps) => {
      const hasV2Sources = person.allSources?.some(
        (source) => !['google', 'microsoft', 'icloud'].includes(source.provider)
      );

      const showStaleSourceBadge =
        option.to === ROUTE_PATHS.addressBook.path &&
        isNylasV3FlagEnabled &&
        (hasV2Sources || hasStaleSources(person.allSources, ['icloud']));

      const showUnreadVoicemailBadge =
        option.to === ROUTE_PATHS.voicemail.path &&
        voicemailQuery.data?.total > 0;

      const badgeData = (
        [
          {
            // stale sources warning badge
            option: ROUTE_PATHS.addressBook.path,
            level: 'warning',
            count: null,
            size: 'small',
          },
          {
            // unread count voicemail badge
            option: ROUTE_PATHS.voicemail.path,
            level: 'danger',
            count: voicemailQuery.data?.total,
            size: 'medium',
          },
        ] as BadgeData[]
      ).find((b) => b.option === option.to);

      const isBadgeVisible =
        (showStaleSourceBadge || showUnreadVoicemailBadge) && badgeData;

      if (!isBadgeVisible) {
        return option;
      }

      return {
        ...option,
        badge: (
          <Badge level={badgeData.level} size={badgeData.size}>
            {badgeData.count}
          </Badge>
        ),
      };
    },
    [person.allSources, isNylasV3FlagEnabled, voicemailQuery.data]
  );

  const sidebarMenu = useMemo(() => {
    const menu: SidebarMenuProps[] = [];
    for (let index = 0; index < numberOfElementsInSidebarMenu; index++) {
      const option = SMALL_SIDEBAR_MENU_OPTIONS[index];
      if (!option) continue;

      const updatedOption = addBadgeToAddressBookOption(option);
      menu.push(updatedOption);
    }
    return menu;
  }, [addBadgeToAddressBookOption, numberOfElementsInSidebarMenu]);

  const drawerMenu = useMemo(() => {
    const menu: SidebarMenuProps[] = [];
    for (
      let index = numberOfElementsInSidebarMenu;
      index < SMALL_SIDEBAR_MENU_OPTIONS.length;
      index++
    ) {
      const option = SMALL_SIDEBAR_MENU_OPTIONS[index];
      if (!option) continue;

      const updatedOption = addBadgeToAddressBookOption(option);
      menu.push(updatedOption);
    }
    return menu;
  }, [addBadgeToAddressBookOption, numberOfElementsInSidebarMenu]);

  const hasParentParameter = (
    parameters: string,
    parentParameters: string[]
  ) => {
    const data = parentParameters
      .map((v) => parameters.includes(v) && v)
      .filter((v) => !!v && v);
    return data[0];
  };

  const lastParameter = window.location.pathname.split('/').pop();
  const parentParameter: string =
    hasParentParameter(window.location.pathname, [
      'addressBook',
      'calls',
      'settings',
      'video-app',
    ]) || 'chat';

  const activeTab = MAIN_TABS.includes(lastParameter)
    ? lastParameter
    : parentParameter;

  const showPortalMenuItem = person.loggedInPersonRole.toLowerCase() !== 'user';

  const renderTouchSidebar =
    isTouchSupported() &&
    hideSecondaryMenu &&
    window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT;

  return (
    <Fragment>
      <SidebarMenuDrawer
        isOpen={isDrawerOpen}
        menu={drawerMenu}
        showPortalMenuItem={showPortalMenuItem}
        activeTab={activeTab}
        testid={SMALL_SIDEBAR_MENU_TESTID}
        onClose={() => {
          setIsDrawerOpen(!isDrawerOpen);
        }}
      />
      <Styled.SidebarMenu
        id="small-sidebar-menu"
        onMouseEnter={() => !isTouchSupported() && setHideSecondaryMenu(false)}
        data-testid={`${SMALL_SIDEBAR_MENU_TESTID}-container`}
      >
        <Styled.TouchSidebar
          $renderTouchSidebar={renderTouchSidebar}
          onClick={() => setHideSecondaryMenu(false)}
        />
        <Styled.TopSidebarMenu>
          {sidebarMenu.map(({ label, icon, to, slug, testid, badge }) => {
            return (
              <SideBarMenuButton
                key={slug}
                {...{
                  label,
                  icon: icon as IconDefinition,
                  isActive: activeTab === slug,
                  to,
                  testid,
                  badge,
                }}
              />
            );
          })}
        </Styled.TopSidebarMenu>

        {drawerMenu.length ? (
          <Styled.Column
            $isActive={isDrawerOpen}
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          >
            <Icon icon="more" size="medium" />
            <Styled.Label $isActive={isDrawerOpen}>More</Styled.Label>
          </Styled.Column>
        ) : (
          <Styled.BottomSidebarMenu>
            {showPortalMenuItem && (
              <SideBarMenuButton
                isExternal
                {...{
                  label: 'Portal',
                  icon: 'portal',
                  to: PORTAL_URL,
                  testid: SMALL_SIDEBAR_MENU_TESTID,
                }}
              />
            )}
            <SideBarMenuButton
              {...{
                label: 'Settings',
                icon: 'settings',
                isActive: activeTab === 'settings',
                to: ROUTE_PATHS.settings.path,
                testid: SMALL_SIDEBAR_MENU_TESTID,
              }}
            />
          </Styled.BottomSidebarMenu>
        )}
      </Styled.SidebarMenu>
    </Fragment>
  );
};

export default inject(
  STORE_PERSON,
  STORE_UI
)(withRouter(observer(SmallSidebarMenu)));
