import rightChevronIcon from 'Assets/images/right-chevron.svg';
import { useAttachments } from 'Components/Chat/hooks/attachments/useAttachments';
import useFileUpload from 'Components/Chat/hooks/attachments/useFileUpload';
import { useAttachmentStore } from 'Components/Chat/store/attachments';
import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import ItemsCarousel from 'react-items-carousel';
import { useParams } from 'react-router';
import { Styled } from './index.styles';
import { UploadStatusBadge } from './UploadStatusBadge';

export type AttachmentsRef = {
  triggerFilePicker: () => void;
};

export const AttachmentsContainer = forwardRef<AttachmentsRef>((_, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => ({
    triggerFilePicker: () => {
      inputRef.current.click();
    },
  }));

  const { attachments, removeAttachment } = useAttachmentStore();
  const { cancelUpload } = useFileUpload();
  const { addAttachmentsViaEvent } = useAttachments();
  const { conversationId } = useParams();

  // set persistence key and load persisted data
  useEffect(() => {
    void (async () => {
      useAttachmentStore.persist.setOptions({
        name: `upload-storage-${conversationId}`,
      });

      useAttachmentStore.getState().resetAttachments();

      // to rehydrate the zustand store using the new name
      await useAttachmentStore.persist.rehydrate();
    })();
  }, [conversationId]);

  const [activeItemIndex, setActiveItemIndex] = React.useState(0);

  const handleFilePicked = async (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ClipboardEvent<HTMLInputElement>
  ) => {
    await addAttachmentsViaEvent(e);
    resetFileInput();
    return;
  };

  const handleRemove = (id: string) => {
    cancelUpload(id);
    removeAttachment(id);
  };

  const resetFileInput = () => {
    const dataTransfer = new DataTransfer();
    inputRef.current.files = dataTransfer.files;
  };

  return (
    <Styled.AttachmentsContainer>
      <Styled.FilePickerInput
        ref={inputRef}
        onChange={handleFilePicked}
        data-testid="attachments-filePickerInput"
      />

      <ItemsCarousel
        infiniteLoop={false}
        gutter={12}
        activePosition="center"
        chevronWidth={80}
        disableSwipe={false}
        alwaysShowChevrons={false}
        numberOfCards={3}
        slidesToScroll={1}
        outsideChevron
        showSlither
        firstAndLastGutter
        requestToChangeActive={setActiveItemIndex}
        rightChevron={<img src={rightChevronIcon} />}
        leftChevron={<Styled.LeftChevron src={rightChevronIcon} />}
        {...{ activeItemIndex }}
      >
        {attachments.map((attachment) => (
          <Styled.ImageWrapper
            $isImage={attachment.isImage}
            key={attachment.id}
          >
            <Styled.ImagePreview
              src={attachment.icon}
              $isImage={attachment.isImage}
              onError={() => handleRemove(attachment.id)}
              onLoad={() => {}}
            />
            {!attachment.isImage && <span>{attachment.file.name}</span>}
            <Styled.IconButton
              icon="cancel-heavy"
              onClick={() => handleRemove(attachment.id)}
              size="mini"
              testid="attachments-buttonDeleteFile"
              variant="secondary"
            />
            <UploadStatusBadge
              isUploading={attachment.status === 'uploading'}
              hasError={attachment.status === 'error'}
            />
          </Styled.ImageWrapper>
        ))}
      </ItemsCarousel>
    </Styled.AttachmentsContainer>
  );
});

AttachmentsContainer.displayName = 'AttachmentsContainer';
