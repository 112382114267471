import { CONTACT_TYPE } from 'Constants/enums';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { MobXProviderContext } from 'mobx-react';
import { IContactDto } from 'Models/ContactModel';
import { Contact, IContact } from 'Models/Contacts';
import { PersonModel } from 'Models/PersonModel';
import React from 'react';
import { useNavigate } from 'react-router';
import { RootStoreProps } from 'Stores/RootStore.types';

export const useEntityActions = () => {
  const navigate = useNavigate();

  const { phoneCall, conversation } = React.useContext(
    MobXProviderContext
  ) as RootStoreProps;

  const makeCall = (personId?: number, phone?: string) => {
    if (personId) {
      void phoneCall.callWithPerson(personId, null);
    } else if (phone) {
      void phoneCall.callWithPerson(null, phone);
    } else {
      throw new Error('Either Phone Number or Person Id is required.');
    }
  };
  const messageUser = (
    personId: number,
    phoneNumber: string,
    contactType: CONTACT_TYPE
  ) => {
    if (contactType === 'Person') {
      void conversation
        .loadOrCreateConversationWithPost(personId)
        .then((resp) => navigate(`/chat/conversations/${resp.data.id}/menu`));
    } else {
      void conversation
        .loadOrCreateConversationWithPost(null, phoneNumber)
        .then((resp) => navigate(`/chat/conversations/${resp.data.id}/menu`));
    }
  };

  return {
    makeCall,
    messageUser,
  };
};

export const useEntityDetails = () => {
  const { person, contact } = React.useContext(
    MobXProviderContext
  ) as RootStoreProps;

  const getDetails = async () => {
    const entityType = person.showPersonDetails?.type;
    const entityId = person.showPersonDetails.id?.toString();

    try {
      if (!entityType || !entityId) {
        return {
          contact: null,
          person: null,
          extrContacts: null,
        };
      }

      if (entityType === 'person') {
        const response = (await person.loadPersonByIdGetIfMissingGet(
          parseInt(entityId, 10)
        ).value) as AxiosResponseT<PersonModel>;

        return { person: response.data, contact: null, extrContacts: null };
      } else if (entityType === 'extrContact') {
        const extrContactsPbo = (await person.getAllContactsWithSameNum(
          entityId
        )) as AxiosResponseT<{ items: IContact[] }>;

        if (extrContactsPbo.data?.items?.length) {
          return {
            extrContacts: extrContactsPbo.data.items.map(
              (item) => new Contact(item)
            ),
          };
        }

        const response = (await contact.loadContactByPhoneNumber(entityId)
          .value) as AxiosResponseT<IContactDto>;

        if (response.data) {
          const extrContactsLookup = [
            person.getExtrContactByPhoneNumber(response.data?.phoneNumber),
          ].filter((contact) => contact);

          if (extrContactsLookup?.length) {
            return {
              extrContacts: extrContactsLookup,
              contact: null,
              person: null,
            };
          } else {
            return {
              contact: response.data,
              person: null,
              extrContacts: null,
            };
          }
        } else {
          return {
            contact: null,
            person: null,
            extrContacts: null,
          };
        }
      } else {
        const response =
          entityId &&
          ((await contact.loadContactByPhoneNumber(entityId)
            .value) as AxiosResponseT<Contact>);

        if (!response?.data) {
          return {
            contact: null,
            person: null,
            extrContacts: null,
          };
        }

        return {
          contact: response.data,
          person: null,
          extrContacts: null,
        };
      }
    } catch (error) {
      console.error('Error while fetching entity details', error);
      return {};
    }
  };

  return {
    getDetails,
  };
};
