import type { LogoButtonProps } from './interfaces';
import logo from 'Assets/images/logo.svg';
import React from 'react';
import { Link } from 'react-router';

import { ROUTE_PATHS } from '../../constants/routing';

export const LogoButton: React.FC<LogoButtonProps> = ({ testid }) => (
  <div className="flex-shrink flex-row">
    <Link
      to={ROUTE_PATHS.latestChat.path}
      className="app-logo"
      data-testid={`${testid}-buttonLogo`}
    >
      <img src={logo} />
    </Link>
  </div>
);
