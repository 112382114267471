import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { NotificationProps } from '../NotificationItem/index.types';
import { NotificationStackPosition } from '../NotificationStack/index.types';
import { STORE_NAME_DEV_TOOLS } from './index.contants';

interface NotificationStore {
  notifications: Map<NotificationStackPosition, NotificationProps[]>;
  addNotify: (notification: NotificationProps) => string | number;
  removeNotify: (
    position: NotificationStackPosition,
    id: string | number
  ) => void;
  updateNotification: (
    position: NotificationStackPosition,
    id: string,
    updatedNotification: NotificationProps
  ) => void;
  clearNotifications: (
    positions?: NotificationStackPosition | NotificationStackPosition[]
  ) => void;
  clearAllNotifications: () => void;
}

/**
 * Notification Store for managing notifications system
 */
export const useNotificationStore = create<NotificationStore>()(
  devtools(
    (set, get) => ({
      notifications: new Map<NotificationStackPosition, NotificationProps[]>([
        ['tl', []],
        ['tc', []],
        ['tr', []],
        ['bl', []],
        ['bc', []],
        ['br', []],
      ]),
      addNotify: (notification) => {
        const id = uuidv4();
        const position = notification.position || 'tr';

        set((state) => {
          const newNotifications = new Map(state.notifications);
          const positionNotifications = [
            ...(newNotifications.get(position) || []),
          ];

          const newNotification = {
            ...notification,
            id,
            position,
            hasAnimated: false,
            createdAt: Date.now(),
          };

          if (notification.onAdd) {
            notification.onAdd(newNotification);
          }

          positionNotifications.unshift(newNotification);
          newNotifications.set(position, positionNotifications);

          return { notifications: newNotifications };
        });

        return id;
      },

      removeNotify: (position, id) => {
        set((state) => {
          const newNotifications = new Map(state.notifications);
          const positionNotifications = newNotifications.get(position) || [];
          const notification = positionNotifications.find((n) => n.id === id);

          if (notification?.onRemove) {
            notification.onRemove(notification);
          }

          newNotifications.set(
            position,
            positionNotifications.filter((n) => n.id !== id)
          );
          return { notifications: newNotifications };
        });
      },

      updateNotification: (
        position: NotificationStackPosition,
        id: string,
        updatedNotification: NotificationProps
      ) => {
        set((state) => {
          const positionNotifications = state.notifications.get(position) || [];
          const updatedNotifications = positionNotifications.map(
            (notification) =>
              notification.id === id ? updatedNotification : notification
          );

          const newNotifications = new Map(state.notifications);
          newNotifications.set(position, updatedNotifications);

          return {
            notifications: newNotifications,
          };
        });
      },

      clearNotifications: (positions) => {
        set((state) => {
          const newNotifications = new Map(state.notifications);

          if (!positions) {
            // Clear all positions
            newNotifications.forEach((_, key) => {
              newNotifications.set(key, []);
            });
          } else if (Array.isArray(positions)) {
            // Clear multiple positions
            positions.forEach((position) => {
              newNotifications.set(position, []);
            });
          } else {
            // Clear single position
            newNotifications.set(positions, []);
          }

          return { notifications: newNotifications };
        });
      },

      clearAllNotifications: () => {
        set((state) => {
          const newNotifications = new Map(state.notifications);
          newNotifications.forEach((_, key) => {
            newNotifications.set(key, []);
          });
          return { notifications: newNotifications };
        });
      },
    }),
    {
      name: STORE_NAME_DEV_TOOLS,
      enabled: process.env.NODE_ENV === 'development',
    }
  )
);
