/**
 * Compares two version strings following semantic versioning format.
 * Supports version strings of any length (e.g., "1.2.3.4").
 * Shorter versions are padded with zeros for comparison (e.g., "1.2" is treated as "1.2.0").
 *
 * @param v1 - First version string (e.g., "1.2.3")
 * @param v2 - Second version string (e.g., "1.2.4")
 * @returns {number}
 *   - Returns 1 if v1 is greater than v2
 *   - Returns -1 if v1 is less than v2
 *   - Returns 0 if versions are equal
 * @throws {Error} If either version string is in invalid format
 *
 * @example
 * compareVersions("2.0.0", "1.9.9")     // returns 1
 * compareVersions("1.2.3", "1.2.4")     // returns -1
 * compareVersions("1.0", "1.0.0")       // returns 0
 * compareVersions("1.2.3", "1.2.3.4")   // returns -1
 * compareVersions("1.2.3.4", "1.2.3")   // returns 1
 */
export const compareVersions = (v1: string, v2: string): number => {
  /**
   * Parses a version string into an array of numbers.
   * Pads with zeros if parts are missing (e.g., "1.2" becomes [1, 2, 0]).
   *
   * @param version - Version string to parse
   * @returns Array of version numbers [major, minor, patch]
   * @throws {Error} If version string format is invalid
   * @internal
   */
  const parseVersion = (version: string): number[] => {
    // Validate version format
    if (!/^\d+(\.\d+)*$/.test(version)) {
      throw new Error(`Invalid version format: ${version}`);
    }

    // Parse version parts (removed the length limitation)
    return version.split('.').map((num) => parseInt(num, 10));
  };

  const version1 = parseVersion(v1);
  const version2 = parseVersion(v2);

  // Pad the shorter version with zeros to match the longer one's length
  const maxLength = Math.max(version1.length, version2.length);
  while (version1.length < maxLength) version1.push(0);
  while (version2.length < maxLength) version2.push(0);

  // Compare each version segment pair until a difference is found
  for (const [a, b] of version1.map((n, i) => [n, version2[i]])) {
    if (a !== b) return a > b ? 1 : -1;
  }

  return 0;
};
