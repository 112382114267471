import { useEffect, useState } from 'react';

const isServer = typeof window !== 'undefined';

/**
 * Hook that monitors the online/offline status of the user's device.
 * It returns a boolean indicating whether the device is currently online (true)
 * or offline (false), and it automatically updates as the network status changes.
 *
 * @returns {boolean} - True if the device is online, false if it is offline.
 */
export function useNetworkStatus(): {
  isOnline: boolean;
} {
  const [isOnline, setIsOnline] = useState(
    isServer ? true : window.navigator.onLine
  );

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { isOnline };
}
