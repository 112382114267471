// index.styles.ts
import styled, { css } from 'styled-components';
import { theme } from '~/styles/theme/Theme';
import { BannerType, BannerVariantType } from './index.types';

const variantColors: Record<BannerType, string> = {
  success: theme.colors.success,
  error: theme.colors.error,
  warning: theme.colors.warning,
  info: theme.colors.primary,
};

const getVariantStyles = (type: BannerType) =>
  variantColors[type] || theme.colors.primary;

const Container = styled.div<{
  $type: BannerType;
  $isStacked?: boolean;
  $isFullWidth: boolean;
}>`
  width: 100%;
  max-width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : '400px')};
  min-width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'auto')};
  ${({ $isStacked }) => $isStacked && 'height: 100%;'}
  padding: 10px;
  border-radius: 4px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  transition: all 200ms;
  box-shadow: ${theme.shadows.lg};
  border: 1px solid ${({ $type }) => getVariantStyles($type)};
  border-left: 15px solid ${({ $type }) => getVariantStyles($type)};
  background-color: ${theme.colors.base};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex: 1;
`;

const Content = styled.div<{
  $variant?: 'default' | 'inline';
}>`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex: 1;
  min-width: 0;
  width: 100%;
  align-items: ${({ $variant }) =>
    $variant === 'inline' ? 'center' : 'flex-start'};
`;

const TextContainer = styled.div`
  flex: 1;
  min-width: 0;
  width: 100%;
`;

const Title = styled.h3`
  all: unset;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MessageContainer = styled.div<{
  $isOnlyMessage: boolean;
  $variant?: 'default' | 'inline';
  $truncate?: boolean;
}>`
  display: flex;

  ${({ $variant, $isOnlyMessage }) =>
    $variant === 'inline'
      ? css`
          align-items: center;
          width: 100%;
          justify-content: space-between;
        `
      : css`
          margin-top: ${$isOnlyMessage ? '0' : '5px'};
          flex-direction: column;
        `}

  /* Scroll behavior is only available when title is present and variant is default */
  ${({ $variant, $isOnlyMessage, $truncate }) =>
    $variant === 'default' &&
    !$isOnlyMessage &&
    !$truncate &&
    css`
      max-height: 100px;
      overflow-y: auto;
      padding-right: 10px;
      margin-right: -15px;

      -ms-overflow-style: 5px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.scrollBar};
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    `}
`;

const Message = styled.p<{ $truncate?: boolean }>`
  all: unset;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
  word-break: break-word;
  width: 100%;

  ${({ $truncate }) =>
    $truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: auto;
`;

const ActionContainer = styled.div<{
  $variant?: 'default' | 'inline';
}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  ${({ $variant }) =>
    $variant === 'inline'
      ? css`
          margin-left: 12px;
          flex-shrink: 0;
          gap: 15px;
        `
      : css`
          margin-top: 8px;
        `}
`;

const IconWrapper = styled.div<{
  $type: BannerType;
  $variant?: BannerVariantType;
}>`
  flex-shrink: 0;
  ${({ $variant }) =>
    $variant === 'inline' &&
    css`
      display: flex;
      align-items: center;
    `}

  svg {
    color: ${({ $type }) => getVariantStyles($type)};
  }
`;

const CloseButton = styled.button`
  all: unset;
  cursor: pointer;
  flex-shrink: 0;
  margin-top: -2px;
  margin-right: -4px;
  padding: 2px;
  border-radius: 50%;
  transition: background-color ${({ theme }) => theme.transitions.default};
`;

const Action = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2px 10px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 3px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  transition: color ${({ theme }) => theme.transitions.default};

  &:hover {
    background-color: ${({ theme }) => theme.colors.border};
  }

  &:active {
    background-color: ${({ theme }) => theme.colorsV2.buttonSecondaryClick};
  }
`;

export const Styled = {
  Container,
  Wrapper,
  Content,
  FooterActions,
  ActionContainer,
  IconWrapper,
  TextContainer,
  Title,
  MessageContainer,
  Message,
  CloseButton,
  Action,
};
