import { Icon } from 'Components/shared/Icon';
import { DateTime } from 'luxon';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';

export const OfflineBanner: React.FC = () => {
  const {
    callLogsStore: { lastSyncDate },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const [offlineMode, setOfflineMode] = React.useState(
    !window.navigator.onLine
  );

  React.useEffect(() => {
    window.ononline = () => {
      setOfflineMode(false);
    };
    window.onoffline = () => setOfflineMode(true);
  }, []);

  const createLastSyncDate = () => {
    const syncDate = DateTime.fromISO(lastSyncDate).setLocale('en-US');
    const meridiem = syncDate.toFormat('a').toLowerCase();
    const date = syncDate.toFormat('f').replace(',', ' at').toLowerCase();
    return date.includes(meridiem) ? date : date + meridiem;
  };

  return (
    <div id="call-logs-offline" className="flex-column">
      <div className="flex-row">
        <Icon icon="warning" className="offline-mode-icon" />
        <span>
          {offlineMode
            ? "Some information may be missing while you're offline."
            : 'It may take a few seconds after a call is ended for it to be included in the different Calls lists.'}
        </span>
      </div>
      {offlineMode && (
        <div className="flex-row latest-sync">
          <span>Latest sync: </span>
          <span>{createLastSyncDate()}</span>
        </div>
      )}
    </div>
  );
};
