import { ChatLayout } from 'Components/Chat/ChatLayout';
import { ChatPage } from 'Components/Chat/ChatPage';

import React from 'react';
import { RouteObject } from 'react-router';

const chatRoutes: RouteObject = {
  path: 'chat/*',
  handle: { title: 'Chat' },
  element: <ChatPage />,
  children: [
    {
      path: 'conversations/:conversationId/*',
      handle: { title: 'Conversation' },
      element: (
          <ChatLayout />
      ),
    },
  ],
};

export default chatRoutes;
