import type { RootStore } from './RootStore';
import { action, observable, makeObservable } from 'mobx';
import { BaseStore } from './BaseStore';

export class CallLogsStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action clearAllData = () => {};

  // Needed for Offline mode
  @observable lastSyncDate = '';
  @action setLastSyncDate = (lastSyncDate: string) =>
    (this.lastSyncDate = lastSyncDate);

  @observable searchQuery = '';
  @action
  setSearchQuery = (searchQuery: string) => (this.searchQuery = searchQuery);
}
export default CallLogsStore;
