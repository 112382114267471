import { Settings } from 'Components/Settings';
import { AudioVideoSettings } from 'Components/Settings/AudioVideoSettings';
import { GeneralSettings } from 'Components/Settings/GeneralSettings';
import { NotificationsSettings } from 'Components/Settings/NotificationsSettings';
import { ProfileSettings } from 'Components/Settings/ProfileSettings';
import { SocialAccounts } from 'Components/Settings/SocialAccounts';
import { SETTINGS_SUBPATHS } from 'Constants/routing';
import React from 'react';
import { Navigate, RouteObject } from 'react-router';

const settingsRoutes: RouteObject = {
  path: 'settings/*',
  element: <Settings />,
  handle: { title: 'Settings' },
  children: [
    {
      index: true,
      element: <Navigate to="profile" replace />,
    },
    {
      path: 'profile',
      element: <ProfileSettings />,
      handle: { title: 'Profile' },
      index: true,
    },
    {
      path: 'general',
      element: <GeneralSettings />,
      handle: { title: 'General' },
    },
    {
      path: 'social-accounts',
      element: <SocialAccounts />,
      handle: { title: 'Social Accounts' },
    },
    {
      path: 'notifications',
      element: <NotificationsSettings />,
      handle: { title: 'Notifications' },
    },
    {
      path: 'audio-video',
      element: <AudioVideoSettings />,
      handle: { title: 'Audio & Video' },
    },
    {
      path: 'phone',
      element: <Navigate to={SETTINGS_SUBPATHS.PROFILE} replace />,
    },
    {
      path: 'voicemails',
      element: <Navigate to={SETTINGS_SUBPATHS.PROFILE} replace />,
    },
  ],
};

export default settingsRoutes;
