const MEGABYTE = 1048576;

/**
 * Returns true if file is larger than the size limit.
 * @param file The file to test.
 * @param sizeLimit Size limit in MB.
 */
export const isFileLargerThan = (file: File, sizeLimit: number): boolean => {
  return file.size > MEGABYTE * sizeLimit;
};

/**
 * Returns true if given file is of one of the wanted MIME type, and false otherwise.
 * Will also return false if file is of one of the extension exceptions, even if has the right MIME type.
 * @param file The file to test.
 * @param formats Array of accepted MIME types.
 * @param exceptions Array of extension exceptions.
 */
export const isFileOfType = (
  file: File,
  mimeTypes: string[],
  exceptions: string[] = []
): boolean => {
  return mimeTypes.some(
    (mimeType) =>
      file.type === mimeType && !exceptions.includes(file.name.split('.').pop())
  );
};
