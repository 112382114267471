import React from "react";
import { Styled } from "../index.styles";
import { BannerActionsProps } from "./index.types";

/**
 * Banner Actions Component
 * @param BannerActionsProps
 * @returns React.ReactElement
 */
export const BannerActions = ({
  actions,
  variant,
}: BannerActionsProps): React.ReactElement => (
  <Styled.ActionContainer $variant={variant}>
    {actions.map((action, index) => (
      <Styled.Action key={index} onClick={action.onClick}>
        {action.label}
      </Styled.Action>
    ))}
  </Styled.ActionContainer>
);
