import { useFetchPresences } from 'Modules/person/index.requests';
import { PollingConfig } from '../index.types';

const usePresencesPolling = ({
  pollingEnabled,
  intervalFunction,
}: PollingConfig) => {
  const { refetch: refetchPresences } = useFetchPresences(
    pollingEnabled,
    intervalFunction
  );

  return { refetchPresences };
};

export default usePresencesPolling;
