import type { SourcesAddressBookItemProps } from './types';
import { LoginResyncButton } from 'Components/LoginResyncButton';
import { ButtonManageSocialAccount } from 'Components/shared/ButtonManageSocialAccount';
import { FeatureFlag } from 'Components/shared/FeatureFlag';
import { NYLAS_V3 } from 'Constants/featureFlags';
import useWarningMessage from 'Hooks/contactSources/useWarningMessage';
import useFeatureFlags from 'Hooks/featureFlags/useFeatureFlags';
import { ISourceAccount } from 'Interfaces/SourceAccount';
import { MobXProviderContext } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { isSourceStale } from 'Utils/contactSyncSource';
import { Styled } from './index.styles';

const checkActionTimeout = (source: ISourceAccount) => {
  const dateNow = moment.utc();

  const dateSynced = moment(source.lastSyncedAt || new Date());
  const dateCrated = moment(source.createdAt);
  return {
    createdBefore5min: dateNow.diff(dateCrated, 'minutes') < 5,
    syncBefore5min: dateNow.diff(dateSynced, 'minutes') < 5,
  };
};

export const SourcesAddressBookItem = ({
  source,
  onShowClick,
  onResyncClick,
  onRemoveClick,
  testId,
}: SourcesAddressBookItemProps) => {
  const { person } = React.useContext<RootStoreProps>(MobXProviderContext);

  const { [NYLAS_V3]: isNylasV3FlagEnabled } = useFeatureFlags([NYLAS_V3]);

  const { createdBefore5min, syncBefore5min } = checkActionTimeout(source);

  const isV3Source = ['google', 'microsoft'].includes(
    source.provider.toLowerCase()
  );

  const isV2Source =
    !isV3Source && !['bhive', 'icloud'].includes(source.provider.toLowerCase());

  const showWarning =
    isNylasV3FlagEnabled &&
    (source.provider.toLowerCase() === 'icloud' ||
      isV2Source ||
      isSourceStale(source.syncState));

  const warningMessage = useWarningMessage(
    showWarning,
    source.provider,
    isV2Source,
    source.syncState,
    true
  );

  const resyncButton = (
    <ButtonManageSocialAccount
      action="RESYNC"
      disabled={syncBefore5min}
      icons={{
        enabled: 'sync-black',
        disabled: 'sync-gray',
      }}
      onClick={!syncBefore5min ? onResyncClick : null}
      testid={`${testId}-buttonResync`}
    />
  );

  return (
    <Styled.SourceItem>
      <Styled.Contact>
        <Styled.ProviderLogo src={source.providerImageSmall} />
        <span>{source.name}</span>

        <Styled.Show onClick={onShowClick} data-testid={`${testId}-buttonShow`}>
          SHOW
        </Styled.Show>
      </Styled.Contact>
      <Styled.Content>{source.text || source.email}</Styled.Content>
      {!source.provider.includes('Bhive') && (
        <Styled.SourceItemActions>
          <FeatureFlag
            flag={NYLAS_V3}
            fallback={
              // Retain the old resync button when V3 is not enabled
              resyncButton
            }
          >
            {isV3Source &&
              (isSourceStale(source.syncState) ? (
                <LoginResyncButton
                  person={person}
                  provider={source.provider}
                  disabled={syncBefore5min}
                />
              ) : (
                resyncButton
              ))}
          </FeatureFlag>

          <ButtonManageSocialAccount
            action="REMOVE"
            disabled={createdBefore5min}
            icons={{
              enabled: 'source-black-link',
              disabled: 'source-gray-link',
            }}
            onClick={!createdBefore5min ? onRemoveClick : null}
            testid={`${testId}-buttonRemove`}
          />
        </Styled.SourceItemActions>
      )}
      {showWarning && (
        <Styled.WarningContainer>
          <Styled.WarningIcon icon="warning-filled" />
          <Styled.Warning>
            <Styled.WarningTitle>Warning: </Styled.WarningTitle>
            <Styled.WarningText>{warningMessage}</Styled.WarningText>
          </Styled.Warning>
        </Styled.WarningContainer>
      )}
    </Styled.SourceItem>
  );
};
