declare global {
  interface Window {
    clarity?: (
      method: 'consent' | 'identify',
      ...params: (string | boolean)[]
    ) => void;
  }
}

/**
 * Consents the cookies, and identifies the user with the provided `customUserId`.
 */
export const identifyClarityUserId = (customUserId: string) => {
  if (!window?.clarity) {
    return;
  }

  window.clarity('consent');
  window.clarity('identify', customUserId);
};

/**
 * Terminates the clarity session by revoking cookie consent.
 */
export const terminateClaritySession = () => {
  if (!window?.clarity) {
    return;
  }

  window.clarity('consent', false);
};
