import { SHOW_PERSONAL_VIDEO_LINK } from 'Constants/env';
import { startCase } from 'lodash';
import { MobXProviderContext } from 'mobx-react';
import React, { useContext, useMemo } from 'react';

import { useNavigate } from 'react-router';
import { isNullOrUndefined } from 'util';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';
import PersonalVideoMeetingInfo from '../PersonalVideoMeetingInfo ';
import { Styled } from './index.styles';

function ContactInfo({ extrContacts, person, placeVideoCallConference }) {
  const {
    person: {
      allContactByPhone,
      setEditContact,
      loggedInAccountId,
      loggedInPersonId,
      selectPersonValueById,
      personAvaliableFeatures: { video: loggedInPersonVideoFeature },
    },
    conversation: { LoggedInUserActiveConferenceConversation },
  } = useContext(MobXProviderContext);

  const navigate = useNavigate();

  // Check if user is in an active video conference
  const userIsInActiveVideoConference = useMemo(
    () => !isNullOrUndefined(LoggedInUserActiveConferenceConversation),
    [LoggedInUserActiveConferenceConversation]
  );

  const conferenceIsActive = useMemo(
    () =>
      !isNullOrUndefined(LoggedInUserActiveConferenceConversation) &&
      !isNullOrUndefined(
        LoggedInUserActiveConferenceConversation.activeConference
      ) &&
      LoggedInUserActiveConferenceConversation.activeConference.sessionId !== '',
    [LoggedInUserActiveConferenceConversation]
  );

  const editContact = (id, phoneNumbers) => (e) => {
    e.stopPropagation();
    const phoneNum = phoneNumbers?.at(0).number;
    const formatedPhoneNum = formatNumberWithNationalCode(phoneNum);
    const contactFromPhoneList =
      phoneNum &&
      allContactByPhone.has(formatedPhoneNum) &&
      allContactByPhone.get(formatedPhoneNum);
    const contactFromAllContact =
      !contactFromPhoneList &&
      extrContacts?.find((contact) => contact.id === id);
    const contact = contactFromPhoneList || contactFromAllContact;
    setEditContact(contact);
    navigate('/addressBook/contact', { state: { from: '/chat' } });
  };

  const email =
  person?.email ||
  (extrContacts &&
    extrContacts[0]?.emails &&
    extrContacts[0]?.emails[0]?.email);

  return (
    <Styled.ContactInfo data-private>
      {/* External Contact Info */}
      {extrContacts && extrContacts.length > 0 && (
        <>
          {extrContacts[0].organizationRole && (
            <Styled.ContactAttribute $secundary>
              {extrContacts[0].organizationRole}
            </Styled.ContactAttribute>
          )}
          {extrContacts[0].organization && (
            <Styled.ContactAttribute $secundary>
              {extrContacts[0].organization}
            </Styled.ContactAttribute>
          )}
          {(extrContacts[0].organizationRole || extrContacts[0].organization) && (
            <Styled.Separator />
          )}
          <Styled.ContactAttribute>
            {`${startCase(extrContacts[0].phoneNumbers[0].type)}: ${formatNumberWithNationalCode(extrContacts[0].phoneNumbers[0].number)}`}
          </Styled.ContactAttribute>
        </>
      )}

      {/* Internal Contact Info */}
      {person && (
        <>
          {person.jobTitle && (
            <Styled.ContactAttribute $secundary>
              {person.jobTitle}
            </Styled.ContactAttribute>
          )}
          {person.department && (
            <Styled.ContactAttribute $secundary>
              {person.department}
            </Styled.ContactAttribute>
          )}
          {(person.jobTitle || person.department) && <Styled.Separator />}
          {person.lines?.map((line) => (
            <Styled.ContactAttribute key={line.id}>
              {`${line.friendlyName}: ${line.PhoneDisplay}`}
            </Styled.ContactAttribute>
          ))}

          {/* Personal Video Meeting Info */}
          {SHOW_PERSONAL_VIDEO_LINK &&
            person.id === loggedInPersonId &&
            (loggedInAccountId === 9 || loggedInAccountId === 862) && (
              <PersonalVideoMeetingInfo
                loggedInPersonVideoFeature={loggedInPersonVideoFeature}
                loggedInPersonId={loggedInPersonId}
                conferenceIsActive={conferenceIsActive}
                userIsInActiveVideoConference={userIsInActiveVideoConference}
                placeVideoCallConference={placeVideoCallConference}
                selectPersonValueById={selectPersonValueById}
              />
            )}
        </>
      )}

      {/* Email */}
      {email && (
        <Styled.EmailAttribute data-private>{email}</Styled.EmailAttribute>
      )}

      {/* Edit Contact Link */}
      {extrContacts && extrContacts.length > 0 && (
        <Styled.EditContactLink
          role="link"
          onClick={editContact(
            extrContacts[0].id,
            extrContacts[0].phoneNumbers
          )}
        >
          Edit Contact
        </Styled.EditContactLink>
      )}
    </Styled.ContactInfo>
  );
}

export default ContactInfo;
