import { DirectoryPanel } from 'Components/DirectoryPanel';
import React from 'react';

const directoryRoutes = {
  path: 'directory',
  element: <DirectoryPanel />,
  handle: { title: 'Directory' },
};

export default directoryRoutes;
