import { CallLogs } from 'Components/CallLogs';
import { CallsDirectoryList } from 'Components/CallLogs/CallsDirectoryList';
import { Channel } from 'Containers/Channel';
import EntityProfile from 'Containers/EntityProfile';
import React from 'react';
import { Navigate, RouteObject } from 'react-router';

const callsRoutes: RouteObject = {
  path: 'calls/*',
  handle: { title: 'Calls' },
  element: (
    <>
      <CallLogs />
      <EntityProfile />
    </>
  ),
  children: [
    {
      index: true,
      element: <Navigate to="all" replace />,
    },
    {
      path: 'missed',
      element: (
        <>
          <Channel />
          <CallsDirectoryList typeOfCallId="missed" />
        </>
      ),
      handle: { title: 'Missed' },
    },
    {
      path: 'incoming',
      element: (
        <>
          <Channel />
          <CallsDirectoryList typeOfCallId="incoming" />
        </>
      ),
      handle: { title: 'Incoming' },
    },
    {
      path: 'outgoing',
      element: (
        <>
          <Channel />
          <CallsDirectoryList typeOfCallId="outgoing" />
        </>
      ),
      handle: { title: 'Outgoing' },
    },
    {
      path: 'all',
      element: (
        <>
          <Channel />
          <CallsDirectoryList typeOfCallId="all" />
        </>
      ),
      handle: { title: 'All' },
    },
  ],
};

export default callsRoutes;
