import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { CallQualitySurveyPayload } from '../index.types';
import {
  CALL_THRESHOLD,
  STORAGE_NAME,
  STORE_NAME_DEV_TOOLS,
} from './index.constants';
import { createStorage } from './index.storage';
import {
  CallSurveyState,
  CallSurveyStore,
  IncrementCallParams,
  StoreType,
} from './index.types';
import { isToday, isValidPersonId } from './index.utils';

export const storeCache = new Map<string, StoreType>();

const getStorageKey = (personId: string) => `${STORAGE_NAME}-${personId}`;

const setInitialState = (personId: string): CallSurveyState =>
  ({
    personId,
    callCount: 0,
    callStartTime: null,
    callEndTime: null,
    callHistory: [],
    currentCallId: null,
    lastSurveyTimestamp: null,
    lastSurveyCallId: null,
    isSurveyEnabled: true,
  } satisfies CallSurveyState);

/**
 * Creates the survey store with persistence and dev tools
 */
export const createCallSurveyStore = (personId: string) => {
  if (!personId) {
    throw new Error('Person ID is required');
  }

  const cacheKey = getStorageKey(personId);
  if (storeCache.has(cacheKey)) {
    const store = storeCache.get(cacheKey);
    if (!store) {
      throw new Error(`Store for person ${personId} not found`);
    }
    return store;
  }

  const store = create<CallSurveyStore>()(
    devtools(
      persist(
        (set, get) => ({
          ...setInitialState(personId),

          setIsSurveyEnabled: (isSurveyEnabled: boolean) => {
            set({ isSurveyEnabled });
          },

          /**
           * Sets the call start time
           * @param callStartTime - ISO string timestamp
           */
          setCallStartTime: (callStartTime: string) => {
            set({ callStartTime });
          },
          /**
           * Sets the call end time
           * @param callEndTime - ISO string timestamp
           */
          setCallEndTime: (callEndTime: string) => {
            set({ callEndTime });
          },
          /**
           * Sets the last call ID
           */
          setCallId: (callId: string) => set({ lastSurveyCallId: callId }),

          /**
           * 1. Increments the call count and manages survey trigger logic
           * 2. If it's a merged call and there's already a merged call at the same timestamp
           * don't increment the counter (counts as 1)
           * 3. Check if we should show survey right after incrementing
           * 4. Emit a custom event that the `SurveyProvider` will listen to
           */
          incrementCallCountByCallId: ({
            callId,
            isMerged = false,
          }: IncrementCallParams) => {
            const state = get();

            if (!state.isSurveyEnabled) {
              return;
            }

            set((state) => {
              const lastMergedCall = state.callHistory.find(
                (call) =>
                  call.isMerged &&
                  call.timestamp ===
                    state.callHistory[state.callHistory.length - 1]?.timestamp
              );

              const shouldIncrement = !(isMerged && lastMergedCall);
              const newCount = shouldIncrement ? state.callCount + 1 : state.callCount;

              const newHistory = [
                ...state.callHistory,
                {
                  callId,
                  isMerged,
                  timestamp: new Date().toISOString(),
                },
              ];

              const hasBeenShownToday = isToday(state.lastSurveyTimestamp);
              const isAtThreshold = newCount === CALL_THRESHOLD;
              const isNewDay = !hasBeenShownToday && state.lastSurveyTimestamp !== null;
              const isAboveThreshold = newCount > CALL_THRESHOLD;

              const shouldTrigger =
                !hasBeenShownToday &&
                (isAtThreshold || (isNewDay && isAboveThreshold));

              if (shouldTrigger) {
                window.dispatchEvent(
                  new CustomEvent('surveyThresholdReached', {
                    detail: {
                      callId,
                      callStartTime: state.callStartTime,
                      callEndTime: state.callEndTime,
                    } satisfies CallQualitySurveyPayload,
                  })
                );
              }

              return {
                ...state,
                callCount: newCount,
                currentCallId: callId,
                callHistory: newHistory,
              };
            });
          },

          /**
           * Resets the call count and clears the survey state
           */
          resetCallCount: () => set(setInitialState(personId)),

          /**
           * Clears the call history
           */
          clearCallHistory: () =>
            set((state) => ({
              ...state,
              callHistory: [],
            })),

          /**
           * Marks the survey as shown and resets the call count
           * @param {string} callId - ID of the call that triggered the survey
           */
          markSurveyShown: (callId: string) => {
            const state = get();
            const now = new Date().toISOString();
            const finalCallId = state.currentCallId || callId;

            set({
              callCount: 0,
              lastSurveyTimestamp: now,
              lastSurveyCallId: finalCallId,
              currentCallId: null,
            });
          },
          clearSurveyStorage: () => {
            localStorage.removeItem(STORAGE_NAME);
            set(setInitialState(personId));
          },
          onSurveySubmitted: () => {
            set({
              callCount: 0,
            });
          },
        }),
        {
          name: cacheKey,
          storage: createStorage,
          partialize: (state) => ({
            personId: state.personId,
            callCount: state.callCount,
            callStartTime: state.callStartTime,
            callEndTime: state.callEndTime,
            currentCallId: state.currentCallId,
            lastSurveyTimestamp: state.lastSurveyTimestamp,
            lastSurveyCallId: state.lastSurveyCallId,
            callHistory: state.callHistory?.slice(-100) || [],
          }),
        }
      ),
      {
        name: STORE_NAME_DEV_TOOLS,
        enabled: process.env.NODE_ENV === 'development',
      }
    )
  );

  if (isValidPersonId(personId, storeCache)) {
    storeCache.set(cacheKey, store);
  }

  return store;
};

export const useCallSurveyStore = (personId: string) => {
  const store = createCallSurveyStore(personId);
  return store;
};
