import { ROUTE_PATHS } from 'Constants/routing';
import { matchPath } from 'react-router';

/**
 * Returns current conversation id -- if in a conversation; otherwise empty string.
 * @param pathname Optionally pass a pathname other than window.location.pathname
 */
export const getCurrentConversationId = (pathname?: string) => {

  const match = matchPath(
    ROUTE_PATHS.conversation.path,
    pathname || window.location.pathname
  );

  return match?.params?.conversationId || '';
};
