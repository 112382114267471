import styled, { css } from 'styled-components';
import { AvatarEditorSizes } from './index.types';

const Container = styled.div<{ $size: AvatarEditorSizes }>`
  ${({ $size }) =>
    $size === 'small'
      ? css`
          width: 79px;
          height: 79px;
        `
      : css`
          width: 120px;
          height: 120px;
        `}
  position: relative;
`;

const ImageContainer = styled.div<{ $isIcon?: boolean; $isInitials?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;

  ${({ $isIcon, theme }) =>
    $isIcon &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid ${theme.colors.whitish};
    `};

  ${({ $isInitials, theme }) =>
    $isInitials &&
    css`
      border: none;
      background-color: ${theme.colors.secondary};
    `}
`;

const Image = styled.img<{ $isIcon?: boolean }>`
  ${({ $isIcon }) =>
    $isIcon
      ? css`
          width: 50px;
          height: 50px;
        `
      : css`
          width: 100%;
          height: 100%;
          object-fit: cover;
        `};
`;

const Initials = styled.div`
  font-size: 27px;
  color: ${({ theme }) => theme.colors.base};
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label<{ $size: AvatarEditorSizes }>`
  ${({ $size }) =>
    $size === 'small'
      ? css`
          width: 24px;
          height: 24px;
        `
      : css`
          width: 38px;
          height: 38px;
        `}
  background-color: ${({ theme }) => theme.colors.blue};
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    ${({ theme }) => theme.colors.blue};
  border-radius: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PencilIcon = styled.img<{ $size: AvatarEditorSizes }>`
  ${({ $size }) =>
    $size === 'small'
      ? css`
          width: 12px;
        `
      : css`
          width: 15px;
          height: 13px;
        `}
`;

export const Styled = {
  Container,
  ImageContainer,
  Image,
  Initials,
  Input,
  Label,
  PencilIcon,
};
