import { POLLING } from 'Constants/featureFlags';
import useFeatureFlags from 'Hooks/featureFlags/useFeatureFlags';
import { MobXProviderContext, observer } from 'mobx-react';
import { useCallLogs } from 'Modules/callLogs/index.request';
import React, { useCallback, useEffect, useRef } from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { isNullOrUndefined } from 'util';
import { randomNumberBetween } from 'Utils/random';
import useConversationsPolling from './hooks/useConversationsPolling';
import useMessagesPolling from './hooks/useMessagesPolling';
import usePresencesPolling from './hooks/usePresencesPolling';

import { PollingFeatureFlags } from './index.types';

const PollingProvider = ({ children }: React.PropsWithChildren) => {
  const { [POLLING]: pollingConfigs } = useFeatureFlags<PollingFeatureFlags>([
    POLLING,
  ]);

  const {
    pusher: { isOnline },
    person: { IsLoggedIn },
    callLogsStore: { searchQuery },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const pollingEnabled = !!(
    IsLoggedIn &&
    !isOnline &&
    pollingConfigs?.polling &&
    !isNullOrUndefined(pollingConfigs?.refreshInterval) &&
    !isNullOrUndefined(pollingConfigs?.jitter)
  );

  const intervalFunction = useCallback(
    () =>
      pollingEnabled
        ? pollingConfigs.refreshInterval +
          randomNumberBetween(0, pollingConfigs.jitter)
        : undefined,
    [pollingEnabled, pollingConfigs?.refreshInterval, pollingConfigs?.jitter]
  );

  const { refetchPresences } = usePresencesPolling({
    pollingEnabled,
    intervalFunction,
  });

  const { refetch: refetchCallLogs } = useCallLogs({
    query: searchQuery,
    enabled: pollingEnabled,
    refetchInterval: intervalFunction,
  });

  const {
    refetchConversations,
    refetchConversation,
    refetchHistoryConversations,
  } = useConversationsPolling({
    pollingEnabled,
    intervalFunction,
  });

  const { refetchMessages } = useMessagesPolling({
    pollingEnabled,
    intervalFunction,
  });

  /**
   * `false` by default, and `true` if polling was previously active.
   * If pusher comes online again, it turns back to `false`.
   */
  const pollingWasActive = useRef(false);

  useEffect(() => {
    if (pollingEnabled) {
      pollingWasActive.current = true;
    }
  }, [pollingEnabled]);

  useEffect(() => {
    if (isOnline && pollingWasActive.current) {
      void refetchCallLogs();
      void refetchPresences();
      void refetchConversations();
      void refetchConversation();
      void refetchHistoryConversations();
      void refetchMessages();
      pollingWasActive.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  return <>{children}</>;
};

export default observer(PollingProvider);
