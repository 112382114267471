import { CONVERSATION_GROUPING, SEARCHABLE_TYPE } from 'Constants/enums';
import { action, observable, makeObservable, computed } from 'mobx';
import { IParticipantModel, ParticipantModel } from 'Models/ParticipantModel';
import { ISearchableItem } from 'Models/SearchResult';

type ConferenceProvider =
  | 'Google Meet'
  | 'WebEx'
  | 'Zoom Meeting'
  | 'GoToMeeting'
  | 'Microsoft Teams'
  | 'BHive';

export interface IConversationModel extends ISearchableItem {
  activeConference?: IConversationConferenceModel;
  creatorId?: number;
  description: string;
  grouping: CONVERSATION_GROUPING;
  isActiveParticipant?: boolean;
  lastMessageDate?: string;
  lastMessageId?: string;
  participants?: Partial<IParticipantModel>[];
  readMessageId?: string;
  topic: string;
  unreadCount: number;
  unreadMentions?: boolean;
  unreadMentionsCount: number;
  unsavedTopic: string | null;
  updated?: string;
}

export interface IConversationConferenceModel {
  id: string;
  adminId: number;
  sessionId: string;
  provider: string;
  start?: string | null;
}
export interface INewConference {
  provider: string;
  visibility: 'Private' | 'Public';
  displayName: string;
  invitees: any[];
  shouldStart: boolean;
  textToCopy: string[];
  type: 'AdHocMeetNow' | 'AdHocScheduled';
}
export interface IPrivateConversationConference {
  id: string;
  adminId: null;
  provider: string | ConferenceProvider;
  displayName: string;
  pin: string;
  dialInInfos: { number: string }[];
}

export class ConversationModel implements IConversationModel {
  static FromResponseDto(responseDto: IConversationModel) {
    return new ConversationModel(
      responseDto.id as string,
      responseDto.created,
      responseDto.unreadCount,
      responseDto.topic,
      responseDto.description,
      responseDto.grouping,
      responseDto.creatorId,
      responseDto.lastMessageId,
      responseDto.updated,
      responseDto.unreadMentions,
      responseDto.unreadMentionsCount,
      responseDto.participants,
      responseDto.searchableType,
      responseDto.activeConference,
      responseDto.lastMessageDate,
      responseDto.readMessageId,
      responseDto.isActiveParticipant
    );
  }

  /**
   * Create a new `ConversationModel` with `id` '0', empty `topic` and `description`, and `CONVERSATION_GROUPING.Channel`
   *
   * @static
   * @param [creatorId]
   * @returns
   */
  static NewChannel(creatorId?: number) {
    return new ConversationModel(
      '0',
      new Date().toISOString(),
      0,
      '',
      '',
      'Channel',
      creatorId,
      null,
      new Date().toISOString(),
      false,
      null,
      null,
      'SearchableDetailsConversation',
      null,
      null,
      null
    );
  }

  public id: string;
  /** Created Time ISO8601 UTC */
  public created: string;

  /**
   * Initial `unreadCount`. This should **ONLY** be used to set the initial unread count in `UiStore` when the Conversation is first loaded.
   *
   * * Use the `UiStore` unread count map as source of truth AFTER initial load (RP 2020-01-20)
   */
  @observable
  public unreadCount: number;

  /**
   * **DEPRECATED** instead use the `UiStore` unread count map as source of truth (RP 2019-07-30)
   *
   * TODO: Remove this, using UiStore unread count map as source of truth (RP 2019-07-30)
   */
  @observable
  public showUnreadCount = true;

  @observable
  public unreadMentions = false;

  @observable
  public unreadMentionsCount: number;

  @observable
  public participants?: Partial<ParticipantModel>[];

  @observable
  public activeConference: IConversationConferenceModel;

  @observable
  public topic: string;

  @observable
  public unsavedTopic: string | null;

  @observable
  public description: string;

  @observable
  public lastMessageId?: string = null;

  @observable
  public readMessageId?: string = null;

  @observable
  public lastMessageDate?: string = null;

  public grouping: CONVERSATION_GROUPING;

  /** Only present if this is a search result */
  public searchableType?: SEARCHABLE_TYPE;

  /** If this is a "Private" (one-on-one) `Conversation`, the `id` of the `Person` the current user is talking to. */
  public creatorId?: number = null;

  public updated?: string = null;

  // TODO techDebt: refactor this after BE add this property to /conversations ENDPOINT
  @observable
  public isActiveParticipant?: boolean = true;

  @action
  setIsActiveParticipant = (isActiveParticipant) =>
    (this.isActiveParticipant = isActiveParticipant);

  constructor(
    id: string,
    created: string,
    unreadCount: number,
    topic: string,
    description: string,
    grouping: CONVERSATION_GROUPING,
    creatorId?: number,
    lastMessageId?: string,
    updated?: string,
    unreadMentions?: boolean,
    unreadMentionsCount?: number,
    participants?: Partial<ParticipantModel>[],
    searchableType?: SEARCHABLE_TYPE,
    activeConference?: IConversationConferenceModel,
    lastMessageDate?: string,
    readMessageId?: string,
    isActiveParticipant?: boolean
  ) {
    makeObservable(this);
    this.id = id;
    this.created = created;
    this.unreadCount = unreadCount;
    this.unreadMentions = unreadMentions;
    this.unreadMentionsCount = unreadMentionsCount;
    this.topic = topic;
    this.unsavedTopic = null;
    this.description = description;
    this.grouping = grouping;
    // TODO techDebt: refactor this after BE add this property to /conversations ENDPOINT
    this.isActiveParticipant =
      isActiveParticipant === undefined ? true : isActiveParticipant;
    this.creatorId = creatorId;
    this.lastMessageId = lastMessageId;
    this.updated = updated;
    this.participants = participants;
    this.searchableType = searchableType;
    this.activeConference = activeConference;
    this.lastMessageDate = lastMessageDate;
    this.readMessageId = readMessageId;
  }

  /**
   * **DEPRECATED** instead use the `UiStore` unread count map as source of truth (RP 2019-07-30)
   *
   * TODO: Remove this, using UiStore unread count map as source of truth (RP 2019-07-30)
   */
  @action
  setShowUnreadCount = (showUnreadCount: boolean) => {
    this.showUnreadCount = showUnreadCount;
  };

  @action
  public setTopicEdit = (topicName: string) => (this.unsavedTopic = topicName);

  @action
  public revertEdit = () => (this.unsavedTopic = null);

  @action
  public commitEdit = () => {
    this.topic = this.unsavedTopic;
    this.unsavedTopic = null;
  };

  /**
   * **DEPRECATED** instead use the `UiStore` unread count map as source of truth (RP 2019-07-30)
   *
   * TODO: Remove this, using UiStore unread count map as source of truth (RP 2019-07-30)
   */
  @action
  setUnreadCount = (unreadCount: number) => {
    return (this.unreadCount = unreadCount);
  };

  @action
  setUnreadMentions = (val: boolean) => {
    this.unreadMentions = val;
  };

  @action
  setUnreadMentionsCount = (unreadMentionsCount: number) => {
    this.unreadMentionsCount = unreadMentionsCount;
  };

  @action
  setTopic = (topic: string) => (this.topic = topic);

  @action
  setDescription = (description: string) => (this.description = description);

  @action
  setUpdated = (updated: string) => (this.updated = updated);

  @action
  setLastMessageId = (lastMessageId: string) =>
    (this.lastMessageId = lastMessageId);

  @action
  setLastMessageDate = (lastMessageDate: string) =>
    (this.lastMessageDate = lastMessageDate);

  @action
  setActiveConference = (activeConference: IConversationConferenceModel) =>
    (this.activeConference = activeConference);

  @computed
  get IsSmsConversation(): boolean {
    return this.participants?.some((participant) => participant.phone) ?? false;
  }
}

export default ConversationModel;
