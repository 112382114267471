import type { DirectoryActionButtonsProps } from './types';
import { CALL_STATE } from 'Constants/enums';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';

import { useNavigate } from 'react-router';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { pushToGTMDataLayer } from '../../../../utils/analytics';
import { IconButton } from '../../../shared/IconButton';
import { Styled } from './index.styles';

export const DirectoryActionButtons: React.FC<DirectoryActionButtonsProps> =
  observer(({ makeCall, makeVideoCall, userId, testid: parentTestid }) => {
    const testid = `${parentTestid}-directoryActionButtons`;
    const buttonSize = 'medium';
    const popupPosition = 'bottom center';
    const navigate = useNavigate();

    const {
      conversation: {
        loadOrCreateConversationWithPost,
        LoggedInUserActiveConferenceConversation,
      },
      phoneCall: { phoneCalls },
      person: { loggedInPersonId, personAvaliableFeatures },
    } = React.useContext<RootStoreProps>(MobXProviderContext);

    const [callState, setCallState] = React.useState(CALL_STATE.Available);

    React.useEffect(() => {
      phoneCalls.filter((phoneCall) => {
        const elementPR = phoneCall.callUri?.split('@')[0].substring(2);
        if (Number(elementPR) === userId) {
          setCallState(
            phoneCall.isCallConnecting
              ? CALL_STATE.Connecting
              : CALL_STATE.Connected
          );
        }
      });
    }, [phoneCalls]);

    const handleOpenChat = async () => {
      const { data } = await loadOrCreateConversationWithPost(userId);
      navigate(`/chat/conversations/${data.id}/menu`);
      pushToGTMDataLayer('directoryMessageUser');
    };

    return (
      <Styled.ActionButtonsWrapper>
        <IconButton
          content="Start messaging"
          icon="chat"
          onClick={handleOpenChat}
          testid={`${testid}-buttonMessage`}
          {...{ popupPosition, size: buttonSize }}
        />
        {userId !== loggedInPersonId && (
          <>
            <IconButton
              content="Start call"
              disabled={{
                title: 'You are currently on a call with this person',
                condition: callState !== CALL_STATE.Available,
              }}
              icon="call"
              onClick={() => makeCall('userId')}
              testid={`${testid}-buttonCall`}
              {...{ popupPosition, size: buttonSize }}
            />
            <IconButton
              content="Start video conference"
              disabled={{
                title: 'Video conference unavailable',
                condition:
                  !!LoggedInUserActiveConferenceConversation ||
                  !personAvaliableFeatures.video.enabled,
              }}
              icon="video"
              onClick={makeVideoCall}
              testid={`${testid}-buttonVideoCall`}
              {...{ popupPosition, size: buttonSize }}
            />
          </>
        )}
      </Styled.ActionButtonsWrapper>
    );
  });
