import SidebarMargin from 'Components/shared/SidebarMargin';
import SmallSidebarMenu from 'Components/SmallSidebarMenu';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from 'Constants/responsiveness';
import {
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_CONFIG,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
  STORE_MESSAGE,
  STORE_CONVERSATION,
} from 'Constants/stores';
import { inject, MobXProviderContext, observer } from 'mobx-react';
import Softphone from 'Modules/softphone/components/Softphone';
import React, { useEffect } from 'react';
import { Outlet, UIMatch, useMatches } from 'react-router';
import { RootStoreProps } from 'Stores/RootStore.types';
import { Styled } from './index.styles';
import { ILayoutProps } from './interfaces';

const Layout = (props: ILayoutProps) => {
  const { ui } = React.useContext<RootStoreProps>(MobXProviderContext);

  const matches = useMatches() as {
    handle: { title: string };
  }[] &
    Omit<UIMatch, 'handle'>[];

  useEffect(() => {
    const baseTitle = 'Broadvoice Communicator';
    // Gather titles from `handle` properties in the route match hierarchy
    const routeTitles = matches
      .map((match) => match.handle?.title)
      .filter(Boolean);

    const unreadCounter =
      ui.totalUnreadMessagesCount > 0
        ? `(${ui.totalUnreadMessagesCount}) `
        : '';

    const pageTitle =
      routeTitles.length > 0
        ? `${unreadCounter}${baseTitle} - ${routeTitles.join(' / ')}`
        : `${unreadCounter}${baseTitle}`;

    document.title = pageTitle;
  }, [matches, ui.totalUnreadMessagesCount, ui]);

  const renderTouchBackground =
    ui.isTouchSupported() &&
    !ui.hideSecondaryMenu &&
    window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT;

  return (
    <>
      <SmallSidebarMenu />
      <Outlet />
      <SidebarMargin />
      <Softphone />
      <Styled.TouchBackground
        $renderTouchBg={renderTouchBackground}
        onClick={() => ui.setHideSecondaryMenu(true)}
      />
    </>
  );
};

export default inject(
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_CONFIG,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
  STORE_MESSAGE,
  STORE_CONVERSATION
)(observer(Layout));
