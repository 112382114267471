import type { UseGoogleAuthLoginProps } from './types';
import { useGoogleLogin } from '@react-oauth/google';
import { GOOGLE_CLIENT, REDIRECT_URL } from 'Constants/env';
import { has } from 'lodash';
import { useMemo } from 'react';
import { handleCodeReceived, sendIpcLoginUrl } from 'Utils/ipcRendererEvents';
import { bugsnagClient } from 'Utils/logUtils';
import { v4 as uuid } from 'uuid';

export const useGoogleAuthLogin = ({
  person,
  isUsingNylasV3,
}: UseGoogleAuthLoginProps) => {
  const SCOPES =
    'https://www.googleapis.com/auth/contacts openid profile email';

  const googleProviderCode = useMemo(
    () => (isUsingNylasV3 ? 'google' : 'gmail'),
    [isUsingNylasV3]
  );

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: SCOPES,
    onSuccess: (resp) => {
      person.sendCodeOfProvider(resp.code, googleProviderCode);
    },
    onError: () => {
      bugsnagClient.notify('Google Login Failed', (event) => {
        event.severity = 'error';
        event.context = 'GoogleAuthLogin';
        event.addMetadata('custom', { function: 'onFailure' });
      });
    },
  });

  const handleLoginClick = () => {
    const IS_ELECTRON =
      typeof window !== 'undefined' && has(window, 'ipcRenderer');
    if (IS_ELECTRON) {
      handleCodeReceived((code) => {
        person.sendCodeOfProvider(code, googleProviderCode);
      });
      sendIpcLoginUrl(
        `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${encodeURIComponent(
          REDIRECT_URL
        )}&response_type=code%20permission%20id_token&scope=${encodeURIComponent(
          SCOPES
        )}&openid.realm&include_granted_scopes=true&client_id=${encodeURIComponent(
          GOOGLE_CLIENT
        )}&nonce=${uuid()}&ss_domain=${encodeURIComponent(
          REDIRECT_URL
        )}&access_type=offline&prompt=consent&origin=${encodeURIComponent(
          REDIRECT_URL
        )}&gsiwebsdk=3&flowName=GeneralOAuthFlow&enable_serial_consent=true&service=lso&o2v=2`
      );
    } else {
      googleLogin();
    }
  };

  return { handleLoginClick, SCOPES };
};
