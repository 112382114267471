import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import { MessagesGetRequest } from 'Interfaces/apiDtos';
import { ResultsCollection } from 'Interfaces/axiosResponse';
import { MobXProviderContext } from 'mobx-react';
import { ConversationModel } from 'Models/ConversationModel';
import { IMessageModel, MessageModel } from 'Models/MessageModel';
import PersonModel from 'Models/PersonModel';
import { PollingConfig } from 'Providers/PollingProvider/index.types';
import { useContext } from 'react';
import { MessageStore } from 'Stores/MessageStore';
import { RootStoreProps } from 'Stores/RootStore.types';
import { getISOStringFromTimeUUID } from 'Utils/timeUUIDParser';
import API from '~/api';

export const QUERY_KEY_FETCH_MESSAGE = 'message.fetchMessage';
export const QUERY_KEY_FETCH_MESSAGES = 'messages.fetchMessages';
/**
 * Fetch messages for a conversation
 * @param conversationId - The ID of the conversation
 * @param messageStore - The message store
 * @returns The messages
 */
export async function fetchMessages(
  conversationId: ConversationModel['id'],
  messageStore: MessageStore,
  showCallMessagesInChat: boolean | undefined
) {
  if (!conversationId) {
    throw new Error('No conversation ID provided');
  }

  const msgsGet: MessagesGetRequest = {
    Limit: 31,
    ShowCallMessagesInChat: showCallMessagesInChat,
    ShowDeleted: true,
    SortDirection: 'Ascending',
  };

  // Get existing messages for the conversation
  const groupedMessages =
    messageStore.groupedMessagesByConversationMap.get(conversationId);

  if (!groupedMessages) {
    // If no messages exist yet, load initial messages
    await messageStore.loadConversationMessages(conversationId, msgsGet);
    return messageStore.groupedMessagesByConversationMap.get(conversationId);
  }

  const lastMessageId = groupedMessages.NewestMessageId;

  // If there is a last message, fetch new messages by skipping the last message
  if (lastMessageId) {
    try {
      const response = await API.get<
      ResultsCollection<IMessageModel>
    >(
        API_ENDPOINTS.ConversationMessagesAscending(conversationId, {
          ...msgsGet,
          SkipId: lastMessageId,
          SkipOver: true,
        })
      );

      const results = response.data?.results;

      if (results?.length > 0) {
        // Process new messages
        results.forEach((messageData) => {
          const newMessage = new MessageModel({
            ...messageData,
            created:
              messageData.created || getISOStringFromTimeUUID(messageData.id),
          });

          // Check if the message already exists in the grouped messages
          const existingMessage = groupedMessages.findMessageById(
            newMessage.id
          );

          if (
            !existingMessage &&
            (showCallMessagesInChat || !newMessage.call)
          ) {
            // Add the new message to the grouped messages
            groupedMessages.pushMessageDirect(newMessage);
          }
        });
      }
    } catch (error) {
      console.error('[fetchMessages] Error:', error);
    }
  }

  return groupedMessages;
}

/**
 * Fetch a message by conversationId and messageId
 * @param conversationId - The ID of the conversation
 * @param messageId - The ID of the message
 * @returns The message
 */
export const fetchMessage = async (
  conversationId: string,
  messageId: string
): Promise<IMessageModel | undefined> => {
  if (!conversationId || !messageId) {
    return undefined;
  }

  const { data } = await API.get<PersonModel>(
    API_ENDPOINTS.ConversationMessageById(conversationId, messageId)
  );

  return data;
};

/**
 * Fetch messages for a conversation
 * @param pollingEnabled - Whether polling is enabled
 * @param intervalFunction - The interval function
 * @returns The messages
 */
export const useFetchMessages = ({
  pollingEnabled,
  intervalFunction,
}: PollingConfig) => {
  const {
    conversation: { CurrentConversation },
    message: messageStore,
    preference: { preferences },
  } = useContext<RootStoreProps>(MobXProviderContext);

  const conversationId = CurrentConversation?.id;

  return useQuery({
    queryKey: [QUERY_KEY_FETCH_MESSAGES, conversationId],
    queryFn: async () => {

      if (!conversationId) {
        return [];
      }

      const messages = await fetchMessages(
        conversationId,
        messageStore,
        preferences?.showCallMessagesInChat
      );

      return messages;
    },
    enabled: pollingEnabled && !!conversationId,
    refetchInterval: intervalFunction,
    staleTime: 0,
  });
};

/**
 * Fetch a message by conversationId and messageId
 * @param conversationId - The ID of the conversation
 * @param messageId - The ID of the message
 * @returns The message
 */
export const useFetchMessage = (conversationId: string, messageId: string) =>
  useQuery({
    queryKey: [QUERY_KEY_FETCH_MESSAGE, conversationId, messageId],
    queryFn: async () => fetchMessage(conversationId, messageId),
  });

export const useMessage = () => ({
  useFetchMessage,
  useFetchMessages,
});
