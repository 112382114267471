export function getBrowserName(userAgent: string) {
  if (userAgent.includes('Firefox')) {
    return 'Mozilla Firefox';
  }
  if (userAgent.includes('SamsungBrowser')) {
    return 'Samsung Internet';
  }
  if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    return 'Opera';
  }
  if (userAgent.includes('Edge')) {
    return 'Microsoft Edge (Legacy)';
  }
  if (userAgent.includes('Edg')) {
    return 'Microsoft Edge (Chromium)';
  }
  if (userAgent.includes('Chrome')) {
    return 'Google Chrome/Chromium';
  }
  if (userAgent.includes('Safari')) {
    return 'Apple Safari';
  }
  return 'unknown';
}
