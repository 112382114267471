import type { LoginResyncButtonProps } from './types';
import { useGoogleAuthLogin } from 'Components/GoogleAuthLogin/useGoogleAuthLoginHook';
import { handleMicrosoftLogIn } from 'Components/Microsoft';
import { NYLAS_V3 } from 'Constants/featureFlags';
import useFeatureFlags from 'Hooks/featureFlags/useFeatureFlags';
import React, { useCallback } from 'react';

import { Styled } from './index.styles';

export const LoginResyncButton = ({
  person,
  provider,
  disabled,
}: LoginResyncButtonProps): JSX.Element => {
  const { [NYLAS_V3]: isUsingNylasV3 } = useFeatureFlags([NYLAS_V3]);
  const { handleLoginClick } = useGoogleAuthLogin({
    person,
    isUsingNylasV3: isUsingNylasV3,
  });

  const onMicrosoftLogin = (code: string) => {
    person.sendCodeOfProvider(code, 'microsoft');
  };

  const handleProviderLogin = useCallback(() => {
    if (provider === 'microsoft') {
      handleMicrosoftLogIn({ onLogin: onMicrosoftLogin });
    }

    if (['gmail', 'google'].includes(provider)) {
      handleLoginClick();
    }
  }, [handleLoginClick, onMicrosoftLogin, provider]);

  return (
    <Styled.LoginButton onClick={handleProviderLogin} disabled={disabled}>
      Login
    </Styled.LoginButton>
  );
};
