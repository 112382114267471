import { MobXProviderContext } from 'mobx-react';
import { useFetchHistoryConversations } from 'Modules/conversation/history/index.requests';
import {
  useFetchConversation,
  useFetchConversations,
} from 'Modules/conversation/index.requests';
import { useContext } from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { PollingConfig } from '../index.types';

const useConversationsPolling = ({
  pollingEnabled,
  intervalFunction,
}: PollingConfig) => {
  const {
    conversation: { CurrentConversation },
  } = useContext<RootStoreProps>(MobXProviderContext);

  const { refetch: refetchConversations } = useFetchConversations(
    pollingEnabled,
    intervalFunction
  );

  const { refetch: refetchConversation } = useFetchConversation(
    pollingEnabled,
    CurrentConversation?.id
  );

  const { refetch: refetchHistoryConversations } = useFetchHistoryConversations(
    pollingEnabled,
    intervalFunction
  );

  return {
    refetchConversations,
    refetchConversation,
    refetchHistoryConversations,
  };
};

export default useConversationsPolling;
